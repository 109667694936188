/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */

import * as request from "superagent";
import { IEntityListModel,IQueryModel } from "./models"
import { requestWithToken } from '../adalConfig';

export class QueryHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public postQuery( queryModel?:IQueryModel, orderBy?:string ):Promise<IEntityListModel>{
        return new Promise<IEntityListModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/query`)
                    .query(orderBy && { orderBy })
                    .send(queryModel)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntityListModel);
                        }
                    });
            });
        });
    }
}



