import * as request from "superagent";
import { requestWithToken } from '../adalConfig';

export function recordEvent(key: string, value: string) {
    const jsonString = JSON.stringify({ ["Event"]: key + "_" + value });
    requestWithToken((tokenError, token) => {
        request
            .put(`${window.location.protocol}//${window.location.host}/AppInsights/event`)
            .set('Authorization', `bearer ${token}`)
            .set('Content-Type', 'application/json')
            .send(jsonString)
            .then();
    })
}