import * as React from "react";
import { IQueryContextValue } from "./QueryContext";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel, IDataCriterionModel } from "../../InRiverApiClient/models";
import { WrapQueryContext } from "./QueryContextWrapper";

export interface ICvlFilterProps {
    label?: string,
    fieldTypeId: string,
    allLabel: string

}

class CvlFilter extends React.Component<ICvlFilterProps & IQueryContextValue>{
    public render() {
        const entityType = this.getEntityType(this.props.fieldTypeId);
        const fieldType = this.getFieldType(this.props.fieldTypeId);
        const cvl = this.getCvl(this.props.fieldTypeId);
        if (!fieldType || !entityType || !cvl) {
            return <React.Fragment />;
        }

        const validCvlValues = this.getValidCvlValues(entityType, cvl);
        const valuesInLocale = this.getCvlValuesInLocale(cvl, validCvlValues);
        const availableOptions = [{ key: "\$All", value: this.props.allLabel },
            ...valuesInLocale];
        const currentOption = availableOptions
            .find(v => v.key === this.getCurrentSettingFromQuery());

        return <React.Fragment >
            <label htmlFor={this.props.fieldTypeId} className="hidden">
                {this.props.label}
            </label>
            <select id={this.props.fieldTypeId}
                value={currentOption && currentOption.key || "\$All"}
                onChange={this.createChangeHandler()}>
                {availableOptions.map(value => <option
                    key={value.key}
                    value={value.key}>
                    {value.value}
                </option>)}
            </select>
        </React.Fragment>;
    }

    public componentDidUpdate() {
        const currentSetting = this.getCurrentSettingFromQuery();
        if (currentSetting === "\$All") {
            return;
        }
        const entityType = this.getEntityType(this.props.fieldTypeId);
        const cvl = this.getCvl(this.props.fieldTypeId);
        const validSetting = entityType
            && cvl
            && this.getValidCvlValues(entityType, cvl)
                .map(x => x.key)
                .includes(currentSetting);

        if (!validSetting) {
            this.props.updateQuery({})
        }
    }

    private getValidCvlValues(entityType: IEntityTypeModel, cvl: ICVLModel): ICVLValueModel[] {
        const cvlValues = this.props.cvlValues.get(cvl.id || "") || [];

        if (!cvl.parentId) {
            return cvlValues;
        }

        const parentCvl = this.props.cvls.find(x => x.id === cvl.parentId);
        if (!parentCvl) {
            return cvlValues;
        }

        const parentFieldTypes = entityType
            && entityType.fieldTypes
            && entityType.fieldTypes.filter(ft => ft.fieldDataType === "CVL" && ft.cvlId === cvl.parentId) || [];


        const parentCvlKeys = this.getValidCvlValues(entityType, parentCvl).map(cvlValue => cvlValue.key as string);
        const acceptedStructure = parentFieldTypes.map(pft => {
            const filters = this.props.combinedQuery.dataCriteria && this.props.combinedQuery.dataCriteria.filter(dc => dc.fieldTypeId === pft.fieldTypeId);
            if (filters) {
                return parentCvlKeys.filter(val => {
                    return filters
                        .map(dc => this.accepts(pft, dc, val))
                        .reduce((acc, currentVal) => acc && currentVal, true)
                });
            }
            return parentCvlKeys;
        });

        const validParentKeys = parentCvlKeys.filter(pcvl =>
            acceptedStructure.map(acc => acc.includes(pcvl || "")).reduce((acc, x) => acc && x, true)
        );

        return cvlValues.filter(cvlval => validParentKeys.includes(cvlval.parentKey || ""));
    }

    private accepts(fieldType: IFieldTypeModel, criteria: IDataCriterionModel, cvlKey?: string) {
        if (!criteria) {
            return true;
        }
        if (!cvlKey) {
            return false;
        }

        if (fieldType.isMultiValue) {
            switch (criteria.operator) {
                case "ContainsAll": return true;
                case "ContainsAny": return (criteria.value as string[]).includes(cvlKey);
                case "NotContainsAll": return true;
                case "NotContainsAny": return !(criteria.value as string[]).includes(cvlKey);;
                case "IsEmpty ": return false;
                case "IsNotEmpty": return true;
            }
        } else {
            switch (criteria.operator) {
                case "Equal": return cvlKey === criteria.value as string;
                case "NotEqual": return cvlKey !== criteria.value as string;
                case "IsEmpty ": return false;
                case "IsNotEmpty": return true;
            }
        }

        return false
    }

    private getCurrentSettingFromQuery() {
        const fieldType = this.getFieldType(this.props.fieldTypeId);
        if (!fieldType || !this.props.query.dataCriteria) {
            return "\$All";
        }
        const criteria = this.props.query.dataCriteria.find(data => data.fieldTypeId === this.props.fieldTypeId);
        if (!criteria || !criteria.value) {
            return "\$All";
        }

        return fieldType.isMultiValue ? (criteria.value as string[]).find(e => true) : criteria.value as string;;
    }

    private getCvlValuesInLocale(cvl: ICVLModel, cvlValues: ICVLValueModel[]) {
        if (cvl.dataType === "LocaleString") {
            return cvlValues.map(cvlValue => {
                return { ...cvlValue, value: cvlValue.value[this.props.language] }
            })
        }

        return cvlValues;
    }

    private getCvl(fieldTypeId: string) {
        const fieldType = this.getFieldType(fieldTypeId);
        if (fieldType) {
            return this.props.cvls.find(cvl => cvl.id === fieldType.cvlId);
        }
        return undefined;
    }

    private getFieldType(fieldTypeId: string) {
        return this.props.entityTypes
            .map(entityType => entityType.fieldTypes as IFieldTypeModel[])
            .map(fieldTypes => fieldTypes.find(fieldType => fieldType.fieldTypeId && fieldType.fieldTypeId === fieldTypeId))
            .find(fieldType => fieldType !== undefined);
    }

    private getEntityType(fieldTypeId: string) {
        return this.props.entityTypes.find(entityType =>
            entityType.fieldTypes
            && entityType.fieldTypes.find(fieldType => fieldType.fieldTypeId === fieldTypeId));
    }

    private createChangeHandler() {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
            const fieldType = this.getFieldType(this.props.fieldTypeId);
            if (!fieldType) {
                return;
            }
            if (e.target.value === "\$All") {
                this.props.updateQuery({} as IQueryModel);
                return;
            }
            this.props.updateQuery({
                dataCriteria: [{
                    "fieldTypeId": this.props.fieldTypeId,
                    "operator": fieldType.isMultiValue ? "ContainsAny" : "Equal",
                    "value": fieldType.isMultiValue ? [e.target.value] : e.target.value
                }]
            });
        };
    }
};

export default WrapQueryContext(CvlFilter);