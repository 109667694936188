import * as React from 'react';
import { InternalDescription } from './InternalDescription';
import { ExternalDescription } from './ExternalDescription';
import { Benefits } from './Benefits';
import { observer } from 'mobx-react';


export const DescriptionAndBenefits = observer(() => {

    return <div className="c-product-related__section">
            <div className="c-product-related__header">
                <h2>Description and benefits</h2>
            </div>

            <div className="c-product-related__content">
                <div className="c-card c-card--flex">
                    <InternalDescription/>
                    <ExternalDescription/>
                </div>

                <Benefits/>
            </div>
        </div>
});