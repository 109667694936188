import * as React from "react";
import EntityListContext, { IEntityListContextValue } from "./EntityListContext";

export function WrapEntityListContext<T>(WrappedComponent: React.ComponentType<T & IEntityListContextValue>) {
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || "Component";

    return class extends React.Component<T, any> {
        public render() {
            return <EntityListContext.Consumer>{
                context => <WrappedComponent {...this.props as T} {...context} />
            }
            </EntityListContext.Consumer>
        }
    }
}