import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'


export const ServiceTop = observer(() => {
    const { detailsStore, modelStore } = useStores();
    const subject: string = "Wrong or missing data in the product catalogue - " + detailsStore.serviceTopData.sapMaterialID;
    const body: string = "Hi, please describe what is missing or incorrect in the product catalogue…";
    const mailString: string = "mailto:" + detailsStore.contactMail + "?subject=" + subject + "&body=" + body;

    return <section className="c-product-top flex justify-between pt-sm">
        <Link to="/">
            <i className="vestas-icon_arrow-previous mr-xxs" />
            <span>Back to catalogue</span>
        </Link>

        <a href={mailString} className="text-black">
            <i className="vestas-icon_email-unread mr-xxs" />
            <span>Wrong or missing data?</span>
        </a>
    </section>
});