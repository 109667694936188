import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'
import * as ReactMarkdown from 'react-markdown';

export const ExternalDescription = observer(() => {

    const { detailsStore } = useStores();

    return <div className="c-card__content c-card__content--half">
        <div className="font-bold mb-xs">ShopVestas description</div>
        <ReactMarkdown source={detailsStore.externalDescription} />
    </div>
});
