import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'

export const AvailabilityAndContacts = observer(() => {

    const { detailsStore } = useStores();
    const sbusStringToShow = (detailsStore.sbus.length > 0) ? detailsStore.sbus.join(", ") : "N/A";
    const countriesStringToShow = (detailsStore.countries.length > 0) ? detailsStore.countries.join(", ") : "N/A";

    return <div className="c-product-related__section">
        <div className="c-product-related__header">
            <h2>Availability and contact</h2>
        </div>

        <div className="c-product-related__content">
            <div className="c-card c-card--flex">
                <div className="c-card__content c-card__content--half c-card__content--border-right">
                    <div className="font-bold mb-sm">Availability</div>

                    <div className="text-sm">
                        <div className="font-bold mb-xxs">SBU's</div>
                        <p className="mb-sm">{sbusStringToShow}</p>

                        <div className="font-bold mb-xxs">Countries</div>
                        <p> {countriesStringToShow}</p>
                    </div>
                </div>

                <div className="c-card__content c-card__content--half">

                    <div className="font-bold mb-xs">Contact</div>
                    <div className="o-flexlist my-xs">
                        {
                            detailsStore.contacts.map((contact, index) =>
                                <div className="o-flexlist__row" key={index}>
                                    <div className="o-flexlist__item" key={contact.area}>{contact.area}</div>
                                    <div className="o-flexlist__item" key={contact.person}>{contact.person}</div>
                                </div>
                            )
                        }
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
    });