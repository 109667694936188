import { observable, computed, autorun, action } from 'mobx';
import { IQueryModel, IDataCriterionModel } from '../InRiverApiClient/models';
import ModelStore from './ModelStore';

export default class CatalogStore {
    @observable public modelStore: ModelStore;
    @observable public language: string = "en";
    @observable public error: boolean = false;
    @observable public businessAreaQuery: IQueryModel = {};
    @observable public categoryQuery: IQueryModel = {};
    @observable public brandQuery: IQueryModel = {};
    @observable public turbineQuery: IQueryModel = {};
    @observable public modelQuery: IQueryModel = {};
    @observable public sbuQuery: IQueryModel = {};
    @observable public countryQuery: IQueryModel = {}; 
    @observable public developmentStageQuery: IQueryModel = {};

    public constructor(modelStore: ModelStore) {
        this.modelStore = modelStore;
    }

    @action public setError(error: boolean) {
        this.error = error;
    }

    @action public setBusinessAreaQuery(query: IQueryModel) {
        this.businessAreaQuery = query;
    }

    @action public setCategoryQuery(query: IQueryModel) {
        this.categoryQuery = query;
    }

    @action public setBrandQuery(query: IQueryModel) {
        this.brandQuery = query;
    }

    @action public setTurbineQuery(query: IQueryModel) {
        this.turbineQuery = query;
    }

    @action public setModelQuery(query: IQueryModel) {
        this.modelQuery = query;
    }

    @action public setSbuQuery(query: IQueryModel) {
        this.sbuQuery = query;
    }

    @action public setCountryQuery(query: IQueryModel) {
        this.countryQuery = query;
    }

    @action public setDevelopmentStageQuery(query: IQueryModel) {
        this.developmentStageQuery = query;
    }

    @action public resetQueries() {
       this.businessAreaQuery = {};
       this.categoryQuery = {};
       this.brandQuery = {};
       this.turbineQuery = {};
       this.modelQuery = {};
       this.sbuQuery = {};
       this.countryQuery = {};
       this.developmentStageQuery = {};
    }

    @computed public get businessAreaQueryValue() {
        return this.getCvlValueFromQueryModel(this.businessAreaQuery);
    }

    @computed public get categoryQueryValue() {
        return this.getCvlValueFromQueryModel(this.categoryQuery);
    }

    @computed public get brandQueryValue() {
        return this.getCvlValueFromQueryModel(this.brandQuery);
    }

    @computed public get turbineQueryValue() {
        return this.getCvlValueFromQueryModel(this.turbineQuery);
    }

    @computed public get modelQueryValue() {
        return this.getCvlValueFromQueryModel(this.modelQuery);
    }

    @computed public get sbuQueryValue() {
        return this.getCvlValueFromQueryModel(this.sbuQuery);
    }

    @computed public get countryQueryValue() {
        return this.getCvlValueFromQueryModel(this.countryQuery);
    }

    @computed public get developmentStageQueryValue() {
        return this.getCvlValueFromQueryModel(this.developmentStageQuery);
    }

    private getCvlValueFromQueryModel(query: IQueryModel) {
        if (query && query.dataCriteria) {
            return this.getCvlValueFromDataCriteria(query.dataCriteria.find(x => true));
        }
        return undefined;
    }

    private getCvlValueFromDataCriteria(dataCriteria?: IDataCriterionModel): string | undefined {
        if (!dataCriteria) {
            return undefined;
        }

        const fieldType = this.modelStore.entityTypes.flatMap(x => x.fieldTypes).find(x => x && x.fieldTypeId === dataCriteria.fieldTypeId);
        if (!fieldType) {
            return undefined;
        }

        const criteriaValue = fieldType.isMultiValue ?
            (dataCriteria.value as string[]).find(x => true)
            : dataCriteria.value as string;

        if (!criteriaValue) {
            return undefined;
        }

        const cvl = this.modelStore.cvls.find(x => x.id === fieldType.cvlId);
        if (!cvl) {
            return undefined;
        }



        const cvlValues = this.modelStore.cvlValues.get(fieldType.cvlId as string);
        if (!cvlValues) {
            return undefined;
        }

        const cvlValue = cvlValues.find(x => x.key === criteriaValue);
        if (!cvlValue) {
            return undefined;
        }

        return cvl.dataType === "LocaleString" ? cvlValue.value[this.language] : cvlValue.value;
    }

}