import * as React from 'react';
import EntityListContext, { IEntityListContextValue } from './EntityListContext';
import { IEntityDataModel, IEntitySummaryModel } from '../../InRiverApiClient/models';
import { EntityHttpSvc } from '../../InRiverApiClient/EntityHttpSvc';
import * as _ from "underscore";
import { entityHttpSvc } from "../../services/InRiverApiClient"
import { WrapEntityListContext } from './EntityListContextWrapper';
interface IEntitiesState {
    entities: IEntityDataModel[];
    promise: Promise<IEntityDataModel[] | void>;

}
export interface IEntitiesProps {
    fields: string[],
    onError?: (error) => void 
}

class Entities extends React.Component<IEntitiesProps & IEntityListContextValue, IEntitiesState> {
    public constructor(props: IEntitiesProps & IEntityListContextValue) {
        super(props);

        this.state = { entities: [], promise: Promise.resolve([]) };
    }

    public render() {
        if (!this.state) {
            return null;
        }
        const renderCall = this.props.children as any;

        return this.state.entities.map((entity, index) => {
            return <React.Fragment key={index}>
                {renderCall(entity)} 
            </React.Fragment>
        });
    }

    public componentDidMount() {
        this.fetchData(this.props.visibleIds);
    }

    public componentDidUpdate(prevProps: IEntityListContextValue) {
        if (!_.isEqual(prevProps.visibleIds, this.props.visibleIds)) {
            this.fetchData(this.props.visibleIds);
        }
    }

    private fetchData(ids: number[]) {
        this.setState({
            ...this.state,
            promise: this.state.promise.then(p =>
                entityHttpSvc.postFetchData({
                    objects: "EntitySummary,FieldValues",
                    entityIds: ids,
                    fieldTypeIds: this.props.fields.join(",")
                }).then(result => {
                    this.setState({ ...this.state, entities: result });
                }, err => this.props.onError && this.props.onError(err)
                ))
        });
    }
}


export default WrapEntityListContext(Entities);

