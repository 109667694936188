import * as React from 'react';
import { observer } from 'mobx-react';
import { SearchBar } from './SearchBar';
import { useStores } from '../hooks/UseStores';
import { Link } from 'react-router-dom';

export const SiteHeader = observer(() => {
    const { userStore, catalogStore, searchStore } = useStores();
 
    return <div className="site-header">
        <div className="site-header__wrap">
            <div className="site-header__content flex-wrap items-center justify-between pb-xs">
                <Link to={`/`}>
                    <h1 className="text-xl my-0 pb-xs md:mr-sm" onClick={() => {
                        catalogStore.resetQueries();
                        searchStore.setSearchString("");
                    }}>
                        Service Products Catalogue
                        </h1>
                </Link>

                <SearchBar />

                <div className="site-header__profile pb-xs">
                    <i className="vestas-icon_user" />
                    <span className="text-sm ml-xs">{userStore.fullName}</span>
                </div>
            </div>
        </div>
    </div>

});