import * as React from 'react';
import EntityListContext, { IEntityListContextValue } from './EntityListContext';
import * as _ from "underscore";
import { WrapEntityListContext } from './EntityListContextWrapper';

export interface IShowMoreButtonBaseProps {
    label: string;
    initialNumberOfShownEntities: number;
    increment: number;
    allIds: number[];
    visibleIds: number[];
    setVisibleIds?: (a: number[]) => void;
}
export interface IShowMoreButtonProps {
    label: string;
    initialNumberOfShownEntities: number;
    increment: number;
}

export class ShowMoreButton extends React.Component<IShowMoreButtonProps & IEntityListContextValue> {

    constructor(props: IShowMoreButtonProps & IEntityListContextValue) {
        super(props);
        this.setInitialVisibleEntities();
    }

    public render() {
        if (this.props.allIds.length === this.props.visibleIds.length) {
            return null;
        }

        return <button type="button" onClick={() => this.showMore()}>{this.props.label}</button>;
    }

    public componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.allIds, this.props.allIds)) {
            this.setInitialVisibleEntities();
        }
    }

    private setInitialVisibleEntities() {
        if (this.props.setVisibleIds) {
            this.props.setVisibleIds(this.props.allIds.slice(0, this.props.initialNumberOfShownEntities));
        }
    }

    private showMore() {
        if (this.props.setVisibleIds) {
            this.props.setVisibleIds(this.props.allIds.slice(0, this.props.visibleIds.length + this.props.increment));
        }
    }
}

export default WrapEntityListContext(ShowMoreButton);