import * as React from 'react'
import { DetailsStore } from '../stores/DetailsStore';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import ModelStore from '../stores/ModelStore';
import SearchStore from '../stores/SearchStore';
import UserStore from '../stores/UserStore';
import CatalogStore from '../stores/CatalogStore';

const browserHistory = createBrowserHistory();
const routingStoreInternal = new RouterStore();
const historyInternal = syncHistoryWithStore(browserHistory, routingStoreInternal);
const detailsStoreInternal = new DetailsStore();
const modelStoreInternal = new ModelStore();

export const storesContext = React.createContext({
    detailsStore: detailsStoreInternal,
    routingStore: routingStoreInternal,
    modelStore: modelStoreInternal,
    searchStore: new SearchStore(modelStoreInternal),
    userStore: new UserStore(),
    catalogStore: new CatalogStore(modelStoreInternal)
})

export const history = historyInternal;