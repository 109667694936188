import { observable, computed, autorun, action } from 'mobx';
import { EntityHttpSvc } from '../InRiverApiClient/EntityHttpSvc';
import { IEntityBundleModel, IFieldSummaryModel, IEntityTypeModel, ICVLModel, ICVLValueModel, ILanguageModel } from '../InRiverApiClient/models';
import { modelHttpSvc } from "../services/InRiverApiClient"
import { systemHttpSvc } from "../services/InRiverApiClient"

const cvlValuesToFetch = [
    "Area",
    "BusinessArea",
    "Type",
    "Country",
    "Category",
    "Scope",
    "SalesBusinessUnits",
    "DevelopmentStage",
    "Brand",
    "Model",
    "ModelVariant",
    "CommercialCategory",
    "CommercialSubCategory"];

export default class ModelStore {
    @observable public entityTypes: IEntityTypeModel[];
    @observable public cvls: ICVLModel[];
    @observable public cvlValues: Map<string, ICVLValueModel[]> = new Map();
    @observable public error: boolean = false;
    @observable public ready: boolean = false;
    @observable public pimLanguages: ILanguageModel[];

    public constructor() {
        // Do not fire too many requests at once...
        // Todo: Consider af api could be rate limited. Probably simpler to limit in proxy (simple semaphore)
        // then code could simplified to a single promise.all
        Promise.all([
            this.loadPIMLanguages(),
            this.loadEntityTypes(),
            this.loadCvls(),
            ...cvlValuesToFetch.map(cvlId => this.loadCvlValue(cvlId))])
            .then(x => this.setReady(true));   
    }

    @action public setEntityTypes(entityTypes: IEntityTypeModel[]) {
        this.entityTypes = entityTypes;
    }

    @action public setCvls(cvls: ICVLModel[]) {
        this.cvls = cvls;
    }

    @action public setPIMLanguages(pimLanguages: ILanguageModel[]) {
        this.pimLanguages = pimLanguages;
    }

    @action public setCvlValues(cvlId: string, cvlValues: ICVLValueModel[]) {
        this.cvlValues.set(cvlId, cvlValues);
    }

    @action public setError(error: boolean) {
        this.error = error;
    }

    @action public setReady(ready: boolean) {
        this.ready = ready;
    }

    private loadEntityTypes() {
        return modelHttpSvc.getGetAllEntityTypes().then(
            result => this.setEntityTypes(result),
            error => this.setError(true));
    }

    private loadCvls() {
        return modelHttpSvc.getGetAllCvls().then(
            result => this.setCvls(result),
            error => this.setError(true)
        );
    }

    private loadCvlValue(cvlId: string) {
        return modelHttpSvc.getGetAllCvlValues(cvlId).then(
            result => this.setCvlValues(cvlId, result),
            error => this.setError(true))
    }

    private loadPIMLanguages() {
        return modelHttpSvc.getGetAllLanguages().then(
            result => this.setPIMLanguages(result),
            error => this.setError(true))
    }
}