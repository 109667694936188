/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */

import * as request from "superagent";
import { IEntityDataModel,IFetchObjectsModel,IEntitySummaryModel,IEntityCreationModel,IMapUniqueValuesModel,IFieldSummaryModel,ISpecificationValueModel,IFieldValueModel,ISetFieldSetModel,ISpecificationValueSummaryModel,ISetSpecificationTemplateModel,ISetSegmentModel,ILinkModel,IEntityBundleModel,IMediaInfoModel,IBase64FileModel,IUrlFileModel,IExeternalUrlFileModel,ICommentModel,ISegment } from "./models"
import { requestWithToken } from '../adalConfig';

export class EntityHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public postFetchData( fetchObjectsModel?:IFetchObjectsModel ):Promise<Array<IEntityDataModel>>{
        return new Promise<Array<IEntityDataModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities:fetchdata`)
                    .send(fetchObjectsModel)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IEntityDataModel>);
                        }
                    });
            });
        });
    }
    
    public getGetEntitySummary( entityId?:number ):Promise<IEntitySummaryModel>{
        return new Promise<IEntitySummaryModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/summary`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntitySummaryModel);
                        }
                    });
            });
        });
    }
    
    public deleteDeleteEntity( entityId?:number ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .delete(`${this.baseUrl}/api/v1.0.0/entities/${entityId}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public postCreateEntity( entityCreationModel?:IEntityCreationModel ):Promise<IEntitySummaryModel>{
        return new Promise<IEntitySummaryModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities:createnew`)
                    .send(entityCreationModel)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntitySummaryModel);
                        }
                    });
            });
        });
    }
    
    public getGetEmptyEntity( entityTypeId?:string , fieldSetId?:string , allFields?:boolean ):Promise<IEntityCreationModel>{
        const params = {
            "entityTypeId":entityTypeId,
            "fieldSetId":fieldSetId,
            "allFields":allFields
        };
        return new Promise<IEntityCreationModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities:getempty`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntityCreationModel);
                        }
                    });
            });
        });
    }
    
    public postMapUniqueValues( mapUniqueValuesModel?:IMapUniqueValuesModel ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities:mapuniquevalues`)
                    .send(mapUniqueValuesModel)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public getCompletenessDetails( entityId?:number ):Promise<Array<IFieldSummaryModel>>{
        return new Promise<Array<IFieldSummaryModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/completenessdetails`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IFieldSummaryModel>);
                        }
                    });
            });
        });
    }
    
    public getGetFields( entityId?:number , fieldTypeIds?:string ):Promise<Array<IFieldSummaryModel>>{
        const params = {
            "fieldTypeIds":fieldTypeIds
        };
        return new Promise<Array<IFieldSummaryModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/summary/fields`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IFieldSummaryModel>);
                        }
                    });
            });
        });
    }
    
    public getGetSpecificationValues( entityId?:number , specificationFieldTypeIds?:string , mandatoryOnly?:boolean ):Promise<Array<ISpecificationValueModel>>{
        const params = {
            "specificationFieldTypeIds":specificationFieldTypeIds,
            "mandatoryOnly":mandatoryOnly
        };
        return new Promise<Array<ISpecificationValueModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/specificationvalues`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISpecificationValueModel>);
                        }
                    });
            });
        });
    }
    
    public putGetFields( entityId?:number , specificationValueModels?:Array<ISpecificationValueModel> ):Promise<Array<ISpecificationValueModel>>{
        return new Promise<Array<ISpecificationValueModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/specificationvalues`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISpecificationValueModel>);
                        }
                    });
            });
        });
    }
    
    public getGetFieldValues( entityId?:number , fieldTypeIds?:string ):Promise<Array<IFieldValueModel>>{
        const params = {
            "fieldTypeIds":fieldTypeIds
        };
        return new Promise<Array<IFieldValueModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/fieldvalues`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IFieldValueModel>);
                        }
                    });
            });
        });
    }
    
    public putSetFieldValues( entityId?:number , fieldValueModels?:Array<IFieldValueModel> ):Promise<Array<IFieldValueModel>>{
        return new Promise<Array<IFieldValueModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/fieldvalues`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IFieldValueModel>);
                        }
                    });
            });
        });
    }
    
    public getFieldHistory( entityId?:number , fieldTypeId?:string ):Promise<IEntitySummaryModel>{
        return new Promise<IEntitySummaryModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/fieldvalues/{fieldTypeId}/revisions`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntitySummaryModel);
                        }
                    });
            });
        });
    }
    
    public putSetFieldSet( entityId?:number , setFieldSetModel?:ISetFieldSetModel ):Promise<IEntitySummaryModel>{
        return new Promise<IEntitySummaryModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/fieldset`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntitySummaryModel);
                        }
                    });
            });
        });
    }
    
    public getGetSpecificationSummary( entityId?:number , specificationFieldTypeIds?:string ):Promise<Array<ISpecificationValueSummaryModel>>{
        const params = {
            "specificationFieldTypeIds":specificationFieldTypeIds
        };
        return new Promise<Array<ISpecificationValueSummaryModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/summary/specification`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISpecificationValueSummaryModel>);
                        }
                    });
            });
        });
    }
    
    public putSetSpecificationTemplate( entityId?:number , setSpecificationTemplateModel?:ISetSpecificationTemplateModel ):Promise<IEntitySummaryModel>{
        return new Promise<IEntitySummaryModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/specificationtemplate`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntitySummaryModel);
                        }
                    });
            });
        });
    }
    
    public putSetSegment( entityId?:number , setSegmentModel?:ISetSegmentModel ):Promise<IEntitySummaryModel>{
        return new Promise<IEntitySummaryModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/segment`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntitySummaryModel);
                        }
                    });
            });
        });
    }
    
    public getGetLinksForEntity( entityId?:number , linkDirection?:string , linkTypeId?:string ):Promise<Array<ILinkModel>>{
        const params = {
            "linkDirection":linkDirection,
            "linkTypeId":linkTypeId
        };
        return new Promise<Array<ILinkModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/links`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ILinkModel>);
                        }
                    });
            });
        });
    }
    
    public getGetEntityBundle( entityId?:number , fieldTypeIds?:string , linkDirection?:string , linkTypeId?:string ):Promise<IEntityBundleModel>{
        const params = {
            "fieldTypeIds":fieldTypeIds,
            "linkDirection":linkDirection,
            "linkTypeId":linkTypeId
        };
        return new Promise<IEntityBundleModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/linksandfields`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntityBundleModel);
                        }
                    });
            });
        });
    }
    
    public getGetAllMedia( entityId?:number ):Promise<Array<IMediaInfoModel>>{
        return new Promise<Array<IMediaInfoModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/media`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IMediaInfoModel>);
                        }
                    });
            });
        });
    }
    
    public getGetMediaDetails(entityId?: number): Promise<Array<IMediaInfoModel>>{
        return new Promise<Array<IMediaInfoModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/mediadetails`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IMediaInfoModel>);
                        }
                    });
            });
        });
    }
    
    public postUploadBase64File( entityId?:number , base64FileModel?:IBase64FileModel ):Promise<IMediaInfoModel>{
        return new Promise<IMediaInfoModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/media:uploadbase64`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IMediaInfoModel);
                        }
                    });
            });
        });
    }
    
    public postUploadMediaFromUrl(entityId?: number, urlFileModel?: IUrlFileModel): Promise<IMediaInfoModel>{
        return new Promise<IMediaInfoModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/media:uploadfromurl`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IMediaInfoModel);
                        }
                    });
            });
        });
    }
    
    public postAddExternalUrl( entityId?:number , urlFileModel?:IExeternalUrlFileModel ):Promise<IMediaInfoModel>{
        return new Promise<IMediaInfoModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/media:addexternalurl`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IMediaInfoModel);
                        }
                    });
            });
        });
    }
    
    public getComments( entityId?:number ):Promise<Array<ICommentModel>>{
        return new Promise<Array<ICommentModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/comments`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ICommentModel>);
                        }
                    });
            });
        });
    }
    
    public postCreateComment(entityId?: number, commentModel?: ICommentModel): Promise<Array<ICommentModel>>{
        return new Promise<Array<ICommentModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/comments`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ICommentModel>);
                        }
                    });
            });
        });
    }
    
    public deleteDeleteComment(entityId?: number, commentId?: number): Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .delete(`${this.baseUrl}/api/v1.0.0/entities/${entityId}/comments/{commentId}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public getStarredEntities():Promise<Array<number>>{
        return new Promise<Array<number>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/starred`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<number>);
                        }
                    });
            });
        });
    }
    
    public putUpdateStarredEntities(entityIds?: Array<number>): Promise<Array<number>>{
        return new Promise<Array<number>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/entities/starred`)
                    .send(entityIds)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<number>);
                        }
                    });
            });
        });
    }
    
    public getGetAllSegments():Promise<Array<ISegment>>{
        return new Promise<Array<ISegment>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/entities/segments`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISegment>);
                        }
                    });
            });
        });
    }
}



