import * as React from 'react';

export interface IEntityListContextValue {
    allIds: number[],
    visibleIds: number[],
    setVisibleIds?: (a: number[]) => void,
    language: string
}

const EntityListContext = React.createContext({
    allIds: [] as number[],
    visibleIds: [] as number[],
    language: "en"
} as IEntityListContextValue);

export default EntityListContext;