import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'
import EntityList from './EntityList/EntityList';
import Entities from './EntityList/Entities';
import { ProductCard } from './ProductCard';
import ShowAll from './EntityList/ShowAll';
import { useEffect } from 'react';

// Go to top of page when a related service is clicked


export const RelatedServices = observer(() => {

    const { detailsStore } = useStores();

    const mainClassName: string = (detailsStore.relatedServices.length > 0) ? "c-product-related__section" : "hidden";

    useEffect(() => {

        window.scrollTo(0, 0);
    });

    return <div className={mainClassName}>
        <div className="c-product-related__header">
            <h2>Related services</h2>
        </div>

        <div className="c-product-related__content">
            <div className="product-overview product-grid">
                <EntityList ids={detailsStore.relatedServices} language="en">
                    <Entities fields={["ServiceCommercialName", "ServiceBusinessArea", "ServiceCommercialCategory", "ServiceScope"]}>
                        {(s) => <ProductCard {...s} />}
                    </Entities>
                    <ShowAll />
                </EntityList>
            </div>
        </div>
    </div>
});