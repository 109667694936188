import * as React from "react";
import QueryContext, { IQueryContextValue } from "./QueryContext";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel } from "../../InRiverApiClient/models";
import { WrapQueryContext } from "./QueryContextWrapper";
import * as _ from "underscore";

export interface IFieldValueFilterProps {
    fieldTypeId: string,
    operator: string,
    value?: string | string[]
}

class FieldValueFilter extends React.Component<IFieldValueFilterProps & IQueryContextValue> {
    public componentDidMount() {
        this.updateQuery();
    }

    public componentDidUpdate() {
        this.updateQuery()
    }

    public componentWillUnmount() {
        this.props.updateQuery({});
    }

    public render() {
        return null;
    }

    private updateQuery() {
        const newQuery = {
            dataCriteria: [
                {
                    fieldTypeId: this.props.fieldTypeId,
                    operator: this.props.operator,
                    value: this.props.value
                }
            ]
        } as IQueryModel;

        if (!_.isEqual(newQuery, this.props.query)) {
            this.props.updateQuery(newQuery);
        }
    }
}

export default WrapQueryContext(FieldValueFilter);