import * as React from 'react';

import { DescriptionAndBenefits } from '../components/DescriptionAndBenefits';
import { ProductDetails } from '../components/ProductDetails';
import { CompatibleTurbines } from '../components/CompatibleTurbine';
import { AvailabilityAndContacts } from '../components/AvailabilityAndContact';
import { observer } from 'mobx-react';
import { Documentation } from '../components/Documentation';
import { RelatedServices } from '../components/RelatedServices';
import { NavigationBar } from '../components/NavigationBar';
import { ServiceInfo } from '../components/ServiceInfo';
import { ServiceTop } from '../components/ServiceTop';
import { useStores } from '../hooks/UseStores'
import { Loader } from '../components/Loader';

export const Details = observer(() => {
    const descriptionAndBenefitsRef = React.useRef<HTMLDivElement>(null);
    const detailsRef = React.useRef<HTMLDivElement>(null);
    const compatibleTurbinesRef = React.useRef<HTMLDivElement>(null);
    const availabilityRef = React.useRef<HTMLDivElement>(null);
    const documentationRef = React.useRef<HTMLDivElement>(null);
    const relatedServicesRef = React.useRef<HTMLDivElement>(null);
    const { detailsStore } = useStores();

    return <React.Fragment>

    <ServiceTop/>
        <ServiceInfo />
        <NavigationBar descriptionAndBenefitsRef={descriptionAndBenefitsRef}
            detailsRef={detailsRef}
            compatibleTurbinesRef={compatibleTurbinesRef}
            availabilityRef={availabilityRef}
            documentationRef={documentationRef}
            relatedServicesRef={relatedServicesRef}/>

        <section className="c-product__related bg-light-grey">

            {!detailsStore.isLoaded && <div className="py-md px-sm text-center">
                <Loader />
            </div>}

            {detailsStore.isLoaded && <div className="c-product-related content-container">
                <div ref={descriptionAndBenefitsRef}>
                    <DescriptionAndBenefits />
                </div>
                <div ref={documentationRef}>
                    <Documentation />
                </div>
                  <div ref={compatibleTurbinesRef}>
                    <CompatibleTurbines />
                </div>
                <div ref={availabilityRef}>
                    <AvailabilityAndContacts />
                </div>
                <div ref={detailsRef}>
                    <ProductDetails />
                </div>
                <div ref={relatedServicesRef}>
                    <RelatedServices />
                </div>
            </div>}
    </section>
    </React.Fragment>
});
