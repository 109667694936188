import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'

export const LanguageSelector = observer(() => {

    const { modelStore, detailsStore } = useStores();

    if (modelStore.ready) {

        return <ul className="language-selector">

            {
                modelStore.pimLanguages.filter(language => language && language.displayName && language.name).map(language =>

                    <li key={language.name} className={language.name === detailsStore.language ? "language-selector__item selected" : "language-selector__item"} onClick={() => {
 
                        detailsStore.setLanguage(language.name as string);
                    }}>
                        <span>{language.displayName}</span>
                        <i className="vestas-icon_checkmark"/>
                    </li>                  
                    )
            }
        </ul>
    }
    return <React.Fragment/>;
});
