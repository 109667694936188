import * as React from "react";
import QueryContext, { IQueryContextValue } from "./QueryContext";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel } from "../../InRiverApiClient/models";
import * as _ from "underscore";
import { WrapQueryContext } from "./QueryContextWrapper";

export interface IMultiSelectCvlFilterProps {
    label: string,
    fieldTypeId: string,

}

class MultiSelectCvlFilter extends React.Component<IMultiSelectCvlFilterProps & IQueryContextValue>{
    public render() {
        const cvl = this.getCvl(this.props.fieldTypeId, this.props.entityTypes, this.props.cvls);
        if (!cvl) {
            return <React.Fragment />;
        }
        const values = this.getCvlValuesInLocale(cvl, this.props.cvlValues.get(cvl && cvl.id ? cvl.id : ""), this.props.language);
        if (!values) {
            return <React.Fragment />;
        }

        const settings = this.getCurrentSettingFromQuery(this.props.query);
        const changeHandler = this.createChangeHandler(cvl, this.props.query, this.props.language, this.props.updateQuery);

        return <div className="mb-md">
            <div className="filter__content-header">Business area</div>
            {
                values.map(value => {
                    return <div key={value.key}>
                        <input id={value.key} type="checkbox" name="checkbox" className="checkbox" checked={settings.includes(value.key as string)} onChange={changeHandler} />
                        <label htmlFor={value.key} className="checkbox-label">{value.value}</label>
                        </div>;
                })
            }
        </div>;
    }

    private getCurrentSettingFromQuery(query: IQueryModel) {
        if (!query || !query.dataCriteria) {
            return [];
        }
        const criteria = query.dataCriteria.find(data => data.fieldTypeId === this.props.fieldTypeId);
        if (!criteria || !criteria.value) {
            return [];
        }

        const keys = criteria.value ? criteria.value as string : "";

        return keys.split(",");
    }

    private createChangeHandler(cvl: ICVLModel, query: IQueryModel, language: string, updateQueryCallback: (IQueryModel) => void) {
        const settings = this.getCurrentSettingFromQuery(query);

        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const key = event.target.id;
            const newSettings = [key].filter(x => event.target.checked || x !== key); 
            if (_.isEqual(newSettings, settings)) {
                return;
            }

            if (newSettings.length === 0) {
                updateQueryCallback({});
                return;
            }

            updateQueryCallback({
                dataCriteria: [
                    {
                        fieldTypeId: this.props.fieldTypeId,
                        language: cvl.dataType === "LocaleString" ? language : undefined,
                        operator: "Equal", // TODO: inriver donot support contains all/any!
                        value: newSettings.join(",")
                    }
                ]
            } as IQueryModel);
        }
    }

    private getCvlValuesInLocale(cvl: ICVLModel, cvlValues: ICVLValueModel[] | undefined, language: string): ICVLValueModel[] | undefined {
        if (!cvlValues) {
            return undefined;
        }
        if (cvl.dataType === "LocaleString") {
            return cvlValues.map(cvlValue => {
                return { ...cvlValue, value: cvlValue.value[language]};
            });
        }

        return cvlValues;
    }


    private getCvl(fieldTypeId: string, entityTypes?: IEntityTypeModel[], cvls?: ICVLModel[]) {
        if (!cvls) {
            return undefined;
        }
        const fieldType = this.getFieldType(fieldTypeId, entityTypes);
        if (fieldType) {
            return cvls.find(cvl => cvl.id === fieldType.cvlId);
        }
        return undefined;
    }

    private getFieldType(fieldTypeId: string, entityTypes?: IEntityTypeModel[]) {
        if (!entityTypes) {
            return undefined;
        }
        return entityTypes
            .map(entityType => entityType.fieldTypes as IFieldTypeModel[])
            .map(fieldTypes => fieldTypes.find(fieldType => fieldType.fieldTypeId && fieldType.fieldTypeId === fieldTypeId))
            .find(fieldType => fieldType !== undefined);
    }
}

export default WrapQueryContext(MultiSelectCvlFilter);