import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'

export const ProductDetails = observer(() => {

    const { detailsStore } = useStores();

    return <div className="c-product-related__section">
        <div className="c-product-related__header">
            <h2>Details</h2>
        </div>

        <div className="c-product-related__content">
            <div className="c-card c-card--flex">
                <div className="c-card__content c-card__content--half c-card__content--border-right">
                    <div className="font-bold mb-xs">Details</div>

                    <div className="o-flexlist my-xs">
                        {
                            detailsStore.details.map((detail, index) =>
                                <div className="o-flexlist__row" key={index}>
                                    <div className="o-flexlist__item">{detail.name}</div>
                                    <div className="o-flexlist__item">{detail.value}</div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
});