
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import './styles/style.generated.css';

ReactDOM.render(

    <React.Fragment>
        <App />
    </React.Fragment>,
    document.getElementById('mainApp') as HTMLElement
);







