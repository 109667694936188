import { ChannelHttpSvc } from "../InRiverApiClient/ChannelHttpSvc";
import { EntityHttpSvc } from "../InRiverApiClient/EntityHttpSvc";
import { LinkHttpSvc } from "../InRiverApiClient/LinkHttpSvc";
import { MediaHttpSvc } from "../InRiverApiClient/MediaHttpSvc";
import { ModelHttpSvc } from "../InRiverApiClient/ModelHttpSvc";
import { QueryHttpSvc } from "../InRiverApiClient/QueryHttpSvc";
import { SyndicateHttpSvc } from "../InRiverApiClient/SyndicateHttpSvc";
import { SystemHttpSvc } from "../InRiverApiClient/SystemHttpSvc";
import { WorkareaHttpSvc } from "../InRiverApiClient/WorkareaHttpSvc";

const baseUrl = `${window.location.protocol}//${window.location.host}/InRiverApi`;

export const channelHttpSvc = new ChannelHttpSvc(baseUrl);
export const entityHttpSvc = new EntityHttpSvc(baseUrl);
export const linkHttpSvc = new LinkHttpSvc(baseUrl);
export const mediaHttpSvc = new MediaHttpSvc(baseUrl);
export const modelHttpSvc = new ModelHttpSvc(baseUrl);
export const queryHttpSvc = new QueryHttpSvc(baseUrl);
export const syndicateHttpSvc = new SyndicateHttpSvc(baseUrl);
export const systemHttpSvc = new SystemHttpSvc(baseUrl);
export const workareaHttpSvc = new WorkareaHttpSvc(baseUrl);