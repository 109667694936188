import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { IEntityDataModel, ICVLValueModel, IFieldValueModel } from '../InRiverApiClient/models';
import { useStores } from '../hooks/UseStores'

function getField(entity: IEntityDataModel, fieldTypeId: string): IFieldValueModel | undefined {
    if (!entity.fieldValues) {
        return undefined;
    }

    return entity.fieldValues.find(f => f.fieldTypeId === fieldTypeId);
}

function getCvlValue(entity: IEntityDataModel, fieldTypeId: string, cvlValues?: ICVLValueModel[]): ICVLValueModel | undefined {
    if (!cvlValues) {
        return undefined;
    }

    const field = getField(entity, fieldTypeId);
    if (field) {
        return cvlValues.find(cvl => cvl.key && cvl.key === field.value);  
    }

    return undefined;
}

const defaultPhoto = "https://asset.productmarketingcloud.com/api/assetstorage/994_0ceaa648-b8dc-4009-8e1b-8351d2800be3/";
export const ProductCard = observer((entity: IEntityDataModel) => {
    const { modelStore } = useStores();
    if (!modelStore.cvlValues) {
        return null;
    }

    const id = entity.entityId;
    const language = "en";

    const nameField = getField(entity, "ServiceCommercialName");
    const name = nameField && nameField.value ? nameField.value[language] : "";

    const businessAreaCvlValue = getCvlValue(entity, "ServiceBusinessArea", modelStore.cvlValues.get("BusinessArea"));
    const businessArea = businessAreaCvlValue ? businessAreaCvlValue.value as string : "";

    const categoryCvlValue = getCvlValue(entity, "ServiceCommercialCategory", modelStore.cvlValues.get("CommercialCategory"));
    const category = categoryCvlValue ? categoryCvlValue.value as string : "";

    const scope = getCvlValue(entity, "ServiceScope", modelStore.cvlValues.get("Scope"));
    const isLocalVariant = (scope && scope.key === "LocalVariant");

    const imageSrc = entity.summary && entity.summary.resourceUrl ? entity.summary.resourceUrl : defaultPhoto;
    return <div className="product-overview__item services-overview__item">
        <Link to={`/service/${id}`} className="product-overview__image">
            <img src={imageSrc + "/thumbnail"} alt="product" />
        </Link>
        <Link to={`/service/${id}`} className="product-overview__info service-overview__info">
            <div className="service-overview__meta">
                {isLocalVariant && <span className="status status--blue status--small inline-block mb-xs">Local Variant</span>}
                <h2 className="product-overview__info-header">{name}</h2>

                <ul className="product-overview__info-vendors">
                    <li>
                        <div>Business area:</div>
                        <div>{businessArea}</div>
                    </li>
                    <li>
                        <div>Commercial Category:</div>
                        <div>{category}</div>
                    </li>
                </ul>
            </div>
        </Link>
    </div>;
});

