import { observable, computed, autorun, action } from 'mobx';
import { IEntityBundleModel, IFieldSummaryModel, IEntityTypeModel, ICVLModel, ICVLValueModel, IQueryModel, IEntityListModel, IDataCriterionModel, ISystemCriterionModel } from '../InRiverApiClient/models';
import { queryHttpSvc } from "../services/InRiverApiClient"
import ModelStore from './ModelStore';
import { recordEvent } from './TelemetryCollector';

export default class SearchStore {
    @observable public modelStore: ModelStore;
    @observable public query: IQueryModel | undefined;
    @observable public isSearching: boolean = false;
    @observable public primaryResultIds: number[] = [];
    @observable public excludedResultIds: number[] = [];
    @observable public searchString: string = "";
    @observable public error: boolean = false;
    private searchPromise: Promise<IEntityListModel | void>;

    public constructor(modelStore: ModelStore) {
        this.searchPromise = Promise.resolve({ count: 0, entityIds: [] } as IEntityListModel);
        this.modelStore = modelStore;

    }

    @computed public get resultEntityIds(): number[] {

        // In most cases the services to display will simply be the primaryResultIds, that are found by executing the selected queries.
        // However when using the 'model variant' filter it is necessary to implement an 'or' operation - because services can match on both having the correct model variant selected 
        // in the modelVariant field OR the 'All+model' value in the model field. Since 'or' is not directly supported we must implement it like this:
        // result = A OR B <=> result = inverse(inversa(A) AND inverse(B)) <=> result = allServices - (inversa(A) AND inverse(B))
        // In this case primaryResultIds becomes all services, and excludedResultIds is 'inversa(A) AND inverse(B)'.
        if (!this.primaryResultIds) {
            return [];
        }
        if (!this.excludedResultIds) {
            return [];
        }

        if (this.excludedResultIds.length < 1) {

            return this.primaryResultIds;
        }

        return this.primaryResultIds.filter(result => !this.excludedResultIds.includes(result))
    }

    @action public setPrimaryResultIds(ids: number[] | undefined) {

        this.primaryResultIds = ids || [];

    };

    @action public setExcludedResultsIds(ids: number[] | undefined) {

        this.excludedResultIds = ids || [];

    };

    @action public setQuery(query: IQueryModel) {
    
        if (query && query.dataCriteria) {

            const primaryDataCriteria = query.dataCriteria.filter(criteria => criteria.fieldTypeId !== 'ServiceModels'
                && criteria.fieldTypeId !== 'ServiceModelVariants');

            const modelsDataCriteria = query.dataCriteria.filter(criteria => criteria.fieldTypeId === 'ServiceModels')
            const modelVariantDataCriteria = query.dataCriteria.filter(criteria => criteria.fieldTypeId === 'ServiceModelVariants')

            const primaryQuery = {

                systemCriteria: query.systemCriteria,
                dataCriteria: primaryDataCriteria

            } as IQueryModel

            if (modelVariantDataCriteria && modelVariantDataCriteria[0]) {

                this.calculateExcludedResultsByModelVariantCriteria(modelVariantDataCriteria[0].value);
            }

            if (modelsDataCriteria && modelsDataCriteria[0]) {

                modelsDataCriteria[0].value = this.addAllValueToCriteriaValue(modelsDataCriteria[0].value);
                primaryQuery.dataCriteria = primaryDataCriteria.concat(modelsDataCriteria);
            }

            this.query = primaryQuery;

            this.isSearching = true;
            
            this.searchPromise = this.searchPromise.then(
                p => queryHttpSvc.postQuery(primaryQuery, "ServiceCommercialName").then(
                    result => this.setPrimaryResultIds(result.entityIds),
                    error => this.setError(true)
                ));
        }        
    }

    @action public setSearchString(str: string) {
        this.searchString = str;
        if (str) {
            recordEvent("Search",str)
        }
   
    }

    @action public setError(error: boolean) {
        this.error = error;
    }

    private addAllValueToCriteriaValue(selectedKey: string) {

        const cvlValuesFound = this.modelStore.cvlValues.get("Model");
        const cvlFound = cvlValuesFound ? cvlValuesFound.filter(cvl => cvl.key === selectedKey.toString()) : undefined;

        if (!cvlFound || cvlFound.length !== 1) {
            return selectedKey;
        }

        const parentKey = cvlFound[0].parentKey;

        return [selectedKey + ";All" + parentKey];
    }

    private calculateExcludedResultsByModelVariantCriteria(selectedModelVariantKey: string){

        const parentParentKey = this.getParentParentKey(selectedModelVariantKey);

        if (!parentParentKey) {
            return;
        }

        const inverseModelCriteria = {
            fieldTypeId: "ServiceModels",
            value: ["All"+parentParentKey],
            operator: "NotContainsAny",
        } as IDataCriterionModel;

        const inverseModelVariantCriteria = {
            fieldTypeId: "ServiceModelVariants",
            value: selectedModelVariantKey,
            operator: "NotContainsAny",
        } as IDataCriterionModel;

        const entityTypeCriteria = {
            type: "EntityTypeId",
            value: "Service",
            operator: "Equal",
        } as ISystemCriterionModel;

        const inverseQuery = {

            dataCriteria: [inverseModelCriteria, inverseModelVariantCriteria],
            systemCriteria: [entityTypeCriteria]

        } as IQueryModel

        this.isSearching = true;

        queryHttpSvc.postQuery(inverseQuery).then(
            inverseResult => this.setExcludedResultsIds(inverseResult.entityIds),
            error => this.setError(true)
        );
    }

    private getParentParentKey(selectedKey: string) : string | undefined {

        const modelVariantCvlValuesFound = this.modelStore.cvlValues.get("ModelVariant");
        const modelVariantCVLFound = modelVariantCvlValuesFound ? modelVariantCvlValuesFound.filter(cvl => cvl.key === selectedKey.toString()) : undefined;

        if (!modelVariantCVLFound || modelVariantCVLFound.length !== 1) {
            return undefined;
        }
        const modelVariantParentKey = modelVariantCVLFound[0].parentKey;

        if (!modelVariantParentKey) {
            return undefined;
        }

        const parentsCvlValuesFound = this.modelStore.cvlValues.get("Model");
        const parentCVLFound = parentsCvlValuesFound ? parentsCvlValuesFound.filter(cvl => cvl.key === modelVariantParentKey.toString()) : undefined;

        if (!parentCVLFound || parentCVLFound.length !== 1) {
            return undefined;
        }
        const parentParentKey = parentCVLFound[0].parentKey;

        if (!parentParentKey) {
            return undefined;
        }

        return parentParentKey;
    }
}