import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

export interface INavigationBarProps {
    descriptionAndBenefitsRef?: React.RefObject<HTMLDivElement>
    detailsRef?: React.RefObject<HTMLDivElement>
    compatibleTurbinesRef?: React.RefObject<HTMLDivElement>
    availabilityRef?: React.RefObject<HTMLDivElement>
    documentationRef?: React.RefObject<HTMLDivElement>
    relatedServicesRef?: React.RefObject<HTMLDivElement>
}

const scrollTo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, barRef: React.RefObject<HTMLElement>, ref?: React.RefObject<HTMLDivElement>) => {
    event.preventDefault();
    const height = barRef.current ? barRef.current.clientHeight : 0;
    const padding = height / 8;
    window.scrollTo(0, ref && ref.current ? ref.current.offsetTop - height - padding  : 0);

}

const inactiveClassName: string = "c-product-navigation__link";
const activeClassName: string = "c-product-navigation__link c-product-navigation__link--active";

export const NavigationBar = observer((props: INavigationBarProps) => {

    const [descriptionAndBenefitsClassName, setDescriptionAndBenefitsClassName] = useState(inactiveClassName);
    const [detailsClassName, setDetailsClassName] = useState(inactiveClassName);
    const [compatibleTurbinesClassName, setCompatibleTurbinesClassName] = useState(inactiveClassName);
    const [availabilityClassName, setAvailabilityClassName] = useState(inactiveClassName);
    const [documentationClassName, setDocumentationClassName] = useState(inactiveClassName);
    const [relatedServiceClassName, setRelatedServiceClassName] = useState(inactiveClassName);

    const barRef = React.useRef<HTMLElement>(null);

    function findCurrentElementAndSetActive(position: number) {

        const barHeight = barRef.current ? barRef.current.clientHeight*1.5 : 0;

        const descriptionAndBenefitsPosition = props.descriptionAndBenefitsRef && props.descriptionAndBenefitsRef.current ? props.descriptionAndBenefitsRef.current.offsetTop - barHeight : 0;
        const detailsPosition = props.detailsRef && props.detailsRef.current ? props.detailsRef.current.offsetTop - barHeight: 0;
        const compatibleTurbinesPosition = props.compatibleTurbinesRef && props.compatibleTurbinesRef.current ? props.compatibleTurbinesRef.current.offsetTop - barHeight: 0;
        const availabilityPosition = props.availabilityRef && props.availabilityRef.current ? props.availabilityRef.current.offsetTop - barHeight : 0;
        const documentationPosition = props.documentationRef && props.documentationRef.current ? props.documentationRef.current.offsetTop - barHeight : 0;
        const relatedServicesPosition = props.relatedServicesRef && props.relatedServicesRef.current ? props.relatedServicesRef.current.offsetTop - barHeight : 0;

        setAllToInactive();
        if (position <= descriptionAndBenefitsPosition || position > descriptionAndBenefitsPosition && position < documentationPosition) {

            setDescriptionAndBenefitsClassName(activeClassName);

        }
        else if (position >= documentationPosition && position < compatibleTurbinesPosition) {

            setDocumentationClassName(activeClassName);
        }
        else if (position >= compatibleTurbinesPosition && position < availabilityPosition) {

            setCompatibleTurbinesClassName(activeClassName);
        }
        else if (position >= availabilityPosition && position < detailsPosition) {

            setAvailabilityClassName(activeClassName);
        }
        else if (position >= detailsPosition && position < relatedServicesPosition) {

            setDetailsClassName(activeClassName);
        }
        else if (position >= relatedServicesPosition) {

            setRelatedServiceClassName(activeClassName);
        }
    }

    function setAllToInactive() {

        setDescriptionAndBenefitsClassName(inactiveClassName);
        setDetailsClassName(inactiveClassName);
        setCompatibleTurbinesClassName(inactiveClassName);
        setAvailabilityClassName(inactiveClassName);
        setDocumentationClassName(inactiveClassName);
        setRelatedServiceClassName(inactiveClassName);
    }

    useEffect(() => {
        const onScroll = e => {

            const position = window.pageYOffset;
            findCurrentElementAndSetActive(position);

        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    });

    return <nav ref={barRef} className="c-product-navigation md:sticky md:top-0 z-10">
        <div className="content-container">
            <ul className="c-product-navigation__list">
                <li className="c-product-navigation__item">
                    <a href="#" className={descriptionAndBenefitsClassName} onClick={e => scrollTo(e, barRef, props.descriptionAndBenefitsRef)}>
                        <span>Description and benefits details</span>
                    </a>
                </li>

                <li className="c-product-navigation__item">
                    <a href="#" className={documentationClassName} onClick={e => scrollTo(e, barRef, props.documentationRef)}>
                        <span>Documentation</span>
                    </a>
                </li>
                
                <li className="c-product-navigation__item">
                    <a href="#" className={compatibleTurbinesClassName} onClick={e => scrollTo(e, barRef, props.compatibleTurbinesRef)}>
                        <span>Compatible turbines</span>
                    </a>
                </li>

                <li className="c-product-navigation__item">
                    <a href="#" className={availabilityClassName} onClick={e => scrollTo(e, barRef, props.availabilityRef)}>
                        <span>Availability & contact</span>
                    </a>
                </li>

                <li className="c-product-navigation__item">
                    <a href="#" className={detailsClassName} onClick={e => scrollTo(e, barRef, props.detailsRef)}>
                        <span>Details</span>
                    </a>
                </li>

                <li className="c-product-navigation__item">
                    <a href="#" className={relatedServiceClassName} onClick={e => scrollTo(e, barRef, props.relatedServicesRef)}>
                        <span>Related services</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
});