import { AuthenticationContext, AdalConfig } from 'react-adal';

function getClientId(uri: string): string {

    if (uri.indexOf('t-serviceproducts') !== -1 || uri.indexOf('app-pim-spc-test-vestas') !== -1) {
        return '72c3edcf-7b96-4a5c-9fde-1d2e60db7dde';
    }
    else if (uri.indexOf('d-serviceproducts') !== -1 || uri.indexOf('app-pim-spc-dev-vestas') !== -1 || uri.indexOf('localhost') !== -1) {
        return '0f090b9f-e453-4a45-990d-d84d9a470d78';
    }
    else {
        return 'e267bc31-0f3f-456e-a2b2-1bdf0e229fc6';
    }
}

function redirectUriCombined(): string {

    return window.location.protocol + '//' + window.location.host + '/';

}


const adalConfig = {
    tenant: 'vestas.onmicrosoft.com',
    redirectUri: redirectUriCombined(),
    clientId: getClientId(window.location.host),
    endpoints: {
        api: 'https://login.microsoftonline.com/c0701940-7b3f-4116-a59f-159078bc3c63'
    },
    cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);


export function requestWithToken<T>(func: (error, token) => void): void {
    authContext.acquireToken(adalConfig.clientId, func);
}