import * as React from "react";
import QueryContext, { IQueryContextValue } from "./QueryContext";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel, IDataCriterionModel } from "../../InRiverApiClient/models";
import { WrapQueryContext } from "./QueryContextWrapper";

export interface IHierarchyCvlFilterProps {
    fieldTypeIds: string[],

}

interface IHierarchyEntry {
    key: string,
    value: string,
    fieldTypeId: string,
    isMultiValue: boolean,
    isExpanded: boolean,
    isSelected: boolean,
    subItems: IHierarchyEntry[]
}

class HierarchyCvlFilter extends React.Component<IHierarchyCvlFilterProps & IQueryContextValue>{
    public render() {
        const fieldTypes = this.getValidatedFieldTypes()
        if (!fieldTypes || fieldTypes.length === 0) {
            return <div>Invalid hierarchy</div>;
        }
        const hierarchy = this.getHierarchyStructure(fieldTypes, "");
        
        return <ul className="product-category-list__list">
            {hierarchy.map(x => <li key={x.key} className="product-category-list__item has-sub-menu">
                {this.renderItem(x)}
                {x.isExpanded ? this.renderSublist(x.subItems) : null}
            </li>)}
        </ul>;
    }

    private renderSublist(hierarchy: IHierarchyEntry[]) {
        if (hierarchy.length <= 0) {
            return null;
        }

        return <ul className="product-category-list__list-sub show">
            {hierarchy.map(x => {
                return <li key={x.key} className="product-category-list__item has-sub-menu">
                    {this.renderItem(x)}
                    {x.isExpanded ? this.renderSublist(x.subItems) : null}
                </li>;
            })}

        </ul>
    }

    private renderItem(entry: IHierarchyEntry) {
        return <div className="product-category-list__link">
            <a href="#" onClick={e => {
                e.preventDefault();
                this.updateOrClearQuery(entry);              
            }
            }>

                <span className={`product-category-list__link-text ${entry.isSelected ? "font-bold":""}`}>{entry.value}</span>
            </a>
        </div>;
    }

    private getHierarchyStructure(fieldTypes: IFieldTypeModel[], parentCvlKey: string): IHierarchyEntry[] {
        if (fieldTypes.length <= 0) {
            return [];
        }

        const first = fieldTypes[0];
        const cvlValues = this.getCvlValuesInLocale(first.cvlId || "").filter(x => parentCvlKey === "" || x.parentKey === parentCvlKey);
        const setting = this.getCurrentSetting();

        return cvlValues.map(x => {
            const subItemlist = this.getHierarchyStructure(fieldTypes.slice(1), x.key as string);
            const selected = this.isSelected(first, x, setting);
            return {
                fieldTypeId: first.fieldTypeId,
                isMultiValue: first.isMultiValue,
                key: x.key,
                value: x.value,
                subItems: subItemlist,
                isSelected: selected,
                isExpanded: selected || this.isExpanded(first, x, subItemlist, setting)
            } as IHierarchyEntry
        });
    }

    private isExpanded(fieldType: IFieldTypeModel, cvlValue: ICVLValueModel, subItems: IHierarchyEntry[], setting?: IDataCriterionModel) {
        if (!setting) {
            return false;
        }

        if (setting.fieldTypeId === fieldType.fieldTypeId) {
            const selectedValue = fieldType.isMultiValue ? (setting.value as string[]).find(x => true) : setting.value as string;
            return selectedValue === cvlValue.key;
        }

        return subItems.reduce((result, next) => result || next.isExpanded, false);
    }

    private isSelected(fieldType: IFieldTypeModel, cvlValue: ICVLValueModel, setting?: IDataCriterionModel) {
        if (!setting) {
            return false;
        }

        if (setting.fieldTypeId === fieldType.fieldTypeId) {
            const selectedValue = fieldType.isMultiValue ? (setting.value as string[]).find(x => true) : setting.value as string;
            return selectedValue === cvlValue.key;
        }

        return false;
    }

    private updateOrClearQuery(entry) {

        if (entry.isSelected || entry.isExpanded) {
            this.props.updateQuery({});
        }
        else {
            this.props.updateQuery({
                dataCriteria: [{
                    "fieldTypeId": entry.fieldTypeId,
                    "operator": entry.isMultiValue ? "ContainsAny" : "Equal",
                    "value": entry.isMultiValue ? [entry.key] : entry.key
                }]
            });
        }
    }

    private getCurrentSetting() {
        if (!this.props.query || !this.props.query.dataCriteria) {
            return undefined;
        }

        return this.props.query.dataCriteria.find(dc => this.props.fieldTypeIds.includes(dc.fieldTypeId || ""));
    }

    private getValidatedFieldTypes() {
        const fieldTypes = this.props.fieldTypeIds.map(id => this.getFieldType(id));
        for (let i = 0; i < fieldTypes.length; i++) {
            const curr = fieldTypes[i];

            if (!curr) {
                return undefined;
            }

            if (i !== 0) {
                const prev = fieldTypes[i - 1] as IFieldTypeModel;
                if (curr.parentCvlId !== prev.cvlId) {
                    return undefined;
                }
            }
        }


        return fieldTypes as IFieldTypeModel[];
    }

    private getCvlValuesInLocale(cvlId : string) {
        const cvl = this.props.cvls.find(x => x.id === cvlId);
        if (!cvl || !cvl.id) {
            return [];
        }
        const cvlValues = this.props.cvlValues.get(cvl.id);
        if (!cvlValues) {
            return [];
        }
        if (cvl.dataType === "LocaleString") {
            return cvlValues.map(cvlValue => {
                return { ...cvlValue, value: cvlValue.value[this.props.language] }
            })
        }

        return cvlValues;
    }

    private getFieldType(fieldTypeId: string) {
        return this.props.entityTypes
            .map(entityType => entityType.fieldTypes as IFieldTypeModel[])
            .map(fieldTypes => fieldTypes.find(fieldType => fieldType.fieldTypeId && fieldType.fieldTypeId === fieldTypeId))
            .find(fieldType => fieldType !== undefined);
    }
}

export default WrapQueryContext(HierarchyCvlFilter)