import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'
import { useState, useEffect, useRef } from 'react';
import * as ReactMarkdown from 'react-markdown';
import { LanguageSelector } from './LanguageSelector';


export const ServiceInfo = observer(() => {
    const { detailsStore, modelStore } = useStores();
    const [imageIndex, setImageIndex] = useState(0);
    const [showMoreDescription, setShowMoreDescription] = useState(false);
    const [languageSelectorVisible, setLanguageSelectorVisible] = useState(false);
    const descriptionMaxSize: number = 800;
    const isLocalVariant = (detailsStore.serviceInfoData.scope === "LocalVariant");

    const description: string = showMoreDescription || detailsStore.serviceInfoData.externalDescription.length < descriptionMaxSize
        ? detailsStore.serviceInfoData.externalDescription : detailsStore.serviceInfoData.externalDescription.slice(0, descriptionMaxSize) + "...";

    const languageSelectorRef = useRef(null);
    useOutsideAlerter(languageSelectorRef);

    function useOutsideAlerter(ref) {

        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setLanguageSelectorVisible(false);
            }
        }

        useEffect(() => {

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
 
                document.removeEventListener("mousedown", handleClickOutside);
            };
        });
    }

    if (modelStore.ready) {

        return <section className="c-product__info content-container pb-md">
            <div className="grid-row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="c-product-image">
                        <div className="product-page__image">
                            <div className="product-page__image-large">

                                {
                                    detailsStore.serviceInfoData.imageUrls.map((url, index) => {
                                        return <div key={url} className="img-container">
                                            <img src={url + "/preview"} alt="product" className={index === imageIndex ? "" : "hidden"} />
                                        </div>
                                    })
                                }

                            </div>

                            <div className="product-page__image-thumbs">

                                {
                                    detailsStore.serviceInfoData.imageUrls.map((url, index) => {
                                        return <div key={url} className="img-container">
                                            <img src={url + "/thumbnail"} alt="product" onClick={e => {

                                                setImageIndex(index);
                                            }
                                            } />
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="c-product-details">
                        <div className="c-product-header">
                            <div className="c-product-header__headline">
                                <h1 className="c-product-header__title">
                                    <ReactMarkdown source={detailsStore.serviceInfoData.name} renderers={{ paragraph: 'span' }}/>
                                    <span className="pr-sm" />
                                    {isLocalVariant && <span className="table-label table-label--large table-label--info table-label--no-margin">Local Variant</span>}
                                </h1>
                            </div>

                            <div className="c-product-header__actions pt-xxs">
                                <div className="relative">
                                    <a href="#" className="text-black" onClick={e => {
                                        e.preventDefault();
                                        setLanguageSelectorVisible(true);
                                    }}>
                                        <i className="vestas-icon_globe mr-xxs" />
                                        <span>{modelStore.pimLanguages.filter(lang => lang.name === detailsStore.language)[0].displayName}</span>
                                    </a>

                                    <div ref={languageSelectorRef} className={languageSelectorVisible ? "" : "hidden"}>
                                        <LanguageSelector />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="c-product-summary grid-row">
                            <div className=" col-sm-12 col-lg-6">
                                <div className="c-product-technical-details">
                                    <div className="font-bold">Description and benefits</div>
                                    <div className="my-xxs">
                                        <ReactMarkdown source={description} />
                                    </div>

                                    {(!showMoreDescription && description.length > descriptionMaxSize) &&
                                        <a href="#" onClick={
                                            e => {
                                                e.preventDefault();
                                                setShowMoreDescription(true);
                                            }}>
                                            Show More
                                    </a>
                                    }
                                </div>
                            </div>

                            <div className=" col-sm-12 col-lg-6">
                                <div className="font-bold">Key details</div>

                                <div className="o-flexlist o-flexlist--small my-xs">
                                    {
                                        detailsStore.serviceInfoData.keyDetails.map((detail, index) =>
                                            <div className="o-flexlist__row" key={index}>
                                                <div className="o-flexlist__item">{detail.name}</div>
                                                <div className="o-flexlist__item">{detail.value}</div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }
    return <React.Fragment/>;    
})