import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'
import { ICompatibleTurbineObject } from '../model/CompatibleTurbineObject';
import { useState } from 'react';

export const CompatibleTurbines = observer(() => {

    const { detailsStore } = useStores();
    const [turbinesShowLimit, setTurbineShowLimit] = useState(true);

    const turbineList: ICompatibleTurbineObject[] = turbinesShowLimit ? detailsStore.compatibleTurbinesList.slice(0, 10) : detailsStore.compatibleTurbinesList;

    const section = (turbineListParam: ICompatibleTurbineObject[]) =>  {

        return Array.from(new Set(turbineListParam.map(turbine => turbine.brand))).map((brand, turbineIndex) => <div key={turbineIndex}>

            <h3 className="c-compatible-turbines__header">{brand}</h3>

            {
                Array.from(new Set(turbineListParam.filter(turbine => turbine.brand === brand).map(t => t.model))).map((model, modelIndex) =>
                    <div className="c-compatible-turbines__list" key={modelIndex}>
                        <div className="c-compatible-turbines__item">

                            <div className="c-compatible-turbines__item-header">{model}</div>
                            <div className="c-compatible-turbines__items">

                                {
                                    Array.from(new Set(turbineListParam.filter(turbine => turbine.brand === brand && turbine.model === model).map(t => t.mw))).map((mw, mwIndex) =>
                                        <div className="c-compatible-turbines__item-line" key={mwIndex}>
                                            <div className="c-compatible-turbines__platform">{mw}</div>

                                            <div className="c-compatible-turbines__mark-versions">
                                                <span>{turbineListParam.filter(turbine => turbine.brand === brand && turbine.model === model && turbine.mw === mw).map(t => t.mark).join(", ")}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>)
    };

    const compatibleTurbineSection = (turbineList.length > 0) ? section(turbineList) : <div className="o-flexlist__item">N/A</div>;
    const showAllClassName = ((detailsStore.compatibleTurbinesList.length > 10) && turbinesShowLimit === true) ? "c-compatible-turbines__link u-link-small" : "hidden";

    return <div className="c-product-related__section">
        <div className="c-product-related__header">
            <h2>Compatible turbines</h2>
        </div>

        <div className="c-product-related__content">
            <div className="c-card">
                <div className="c-card__content">
                    <div className="c-compatible-turbines">
                        <div className="c-compatible-turbines__container">
                            {
                                compatibleTurbineSection
                            }

                        </div>
                    </div>

                    <a href="#" className={showAllClassName} onClick={e => {
                        e.preventDefault();

                        setTurbineShowLimit(false)
                    }
                    }>{"Show all " + detailsStore.compatibleTurbinesList.length + " rows"}</a>
                </div>
            </div>
        </div>
    </div>
});
