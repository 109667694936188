import * as React from "react";
import QueryContext from "./QueryContext";
import { IQueryModel, IEntityTypeModel, ICVLModel, ICVLValueModel } from "../../InRiverApiClient/models";
import * as _ from "underscore";
import { recordEvent } from "src/stores/TelemetryCollector";

export interface IFilterProps{
    entityTypes: IEntityTypeModel[],
    cvls: ICVLModel[],
    cvlValues: Map<string, ICVLValueModel[]>,
    language: string,
    onQueryChanged?: (IQueryModel) => void
}

export interface IFilterState {
    queries: Map<string, IQueryModel>,
    
}

export class Filter extends React.Component<IFilterProps, IFilterState>{
    constructor(props) {
        super(props);
        this.state = { queries: new Map<string, IQueryModel>() }
    }

    public render() {
        const combinedQuery = this.getCombinedQuery(this.state);
        return <div>
            {
                React.Children.map(this.props.children, (filter, index) => {
                    const key = this.getKey(filter) || String(index);
                    const query = this.state && this.state.queries && this.state.queries.get(key) ? this.state.queries.get(key) as IQueryModel: {} as IQueryModel;
                    const updateQuery = q => this.updateQuery(key, q);
                    return <QueryContext.Provider value={{ query, combinedQuery, updateQuery, cvls: this.props.cvls, cvlValues: this.props.cvlValues, entityTypes: this.props.entityTypes, language: this.props.language }}>
                        {filter}
            </QueryContext.Provider>
        })
}
        </div>
    }

    public componentDidUpdate(prevProps: IFilterProps, prevState: IFilterState) {
        if (this.props.onQueryChanged) {
            const prevQuery = this.getCombinedQuery(prevState);
            const query = this.getCombinedQuery(this.state);
            this.RecordCategoryChangeIfUpdated(prevQuery, query)
            if (!_.isEqual(prevQuery, query)) {
                this.props.onQueryChanged(query);
            }
        }
    }
    
    private updateQuery(key, query: IQueryModel) {
        const nextState: IFilterState = { queries: new Map<string, IQueryModel>() };
        this.state.queries.forEach((v, k) => nextState.queries.set(k, v));
        nextState.queries.set(key, query);
        this.setState(nextState);
    }

    private RecordCategoryChangeIfUpdated(prevQuery: IQueryModel, query: IQueryModel){
        if (prevQuery && prevQuery.dataCriteria && query && query.dataCriteria) {
            const oldCategory = prevQuery.dataCriteria.filter(c => c.fieldTypeId === "ServiceCommercialCategory")[0]
            const oldSubCategory = prevQuery.dataCriteria.filter(c => c.fieldTypeId === "ServiceCommercialSubCategory")[0]
        
            const newCategory = query.dataCriteria.filter(c => c.fieldTypeId === "ServiceCommercialCategory")[0]
            const newSubCategory = query.dataCriteria.filter(c => c.fieldTypeId === "ServiceCommercialSubCategory")[0]
            if (newCategory) {
                if (oldCategory === undefined || oldCategory.value !== newCategory.value) {
                    recordEvent("Category",newCategory.value)
                }
            }
            if (newSubCategory) {
                if (oldSubCategory === undefined || oldSubCategory.value !== newSubCategory.value) {
                    recordEvent("Category",newSubCategory.value)
                }
            }
        }
    }

    private getCombinedQuery(state: IFilterState | undefined) { 
        if (!state) {
            return {} as IQueryModel;
        }
        const result = Array.from(state.queries.values()).reduce((acc, a) => {
            if (!a) {
                return acc;
            }

            if (a.dataCriteria) {
                acc.dataCriteria = acc.dataCriteria ? acc.dataCriteria.concat(a.dataCriteria) : a.dataCriteria;
            }

            if (a.systemCriteria) {
                acc.systemCriteria = acc.systemCriteria ? acc.systemCriteria.concat(a.systemCriteria) : a.systemCriteria;
            }

            return acc;
        }, {} as IQueryModel)

        if (!result) {
            return {} as IQueryModel;
        }

        return result;
    }

    private getKey(a: any) {
        const element = a as React.ReactElement;
        if (element && element.key) {
            return String(element.key);
        }
        return undefined;
    }
}
