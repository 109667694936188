import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores';
import * as ReactMarkdown from 'react-markdown';

export const InternalDescription = observer(() => {

    const { detailsStore } = useStores();

    return <div className="c-card__content c-card__content--half c-card__content--border-right">
        <div className="font-bold mb-xs">Internal description</div>
        <ReactMarkdown source={detailsStore.internalDescription} />
    </div>
});