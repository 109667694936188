/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */


import * as request from "superagent";
import { IEntityTypeModel,ILanguageModel,IFieldSetModel,ICVLModel,ICVLValueModel,IEntitySummaryModel,ISpecificationFieldTypeModel } from "./models"
import { requestWithToken } from '../adalConfig';

export class ModelHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public getGetAllEntityTypes( entityTypeIds?:string ):Promise<Array<IEntityTypeModel>>{
        const params = {
            "entityTypeIds":entityTypeIds
        };
        return new Promise<Array<IEntityTypeModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/entitytypes`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IEntityTypeModel>);
                        }
                    });
            });
        });
    }
    
    public getGetAllLanguages():Promise<Array<ILanguageModel>>{
        return new Promise<Array<ILanguageModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/languages`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ILanguageModel>);
                        }
                    });
            });
        });
    }
    
    public getGetAllFieldSets():Promise<Array<IFieldSetModel>>{
        return new Promise<Array<IFieldSetModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/fieldsets`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IFieldSetModel>);
                        }
                    });
            });
        });
    }
    
    public getGetAllCvls():Promise<Array<ICVLModel>>{
        return new Promise<Array<ICVLModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/cvls`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ICVLModel>);
                        }
                    });
            });
        });
    }
    
    public getGetAllCvlValues( cvlId?:string ):Promise<Array<ICVLValueModel>>{
        return new Promise<Array<ICVLValueModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/cvls/${cvlId}/values`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ICVLValueModel>);
                        }
                    });
            });
        });
    }
    
    public postCreateCvlValue( cvlId?:string , cvlValueModel?:ICVLValueModel ):Promise<ICVLValueModel>{
        return new Promise<ICVLValueModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/model/cvls/${cvlId}/values`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as ICVLValueModel);
                        }
                    });
            });
        });
    }
    
    public getGetCvlValue( cvlId?:string , key?:string ):Promise<ICVLValueModel>{
        return new Promise<ICVLValueModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/cvls/${cvlId}/values/${key}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as ICVLValueModel);
                        }
                    });
            });
        });
    }
    
    public putUpdateCvlValue( cvlId?:string , key?:string , cvlValueModel?:ICVLValueModel ):Promise<ICVLValueModel>{
        return new Promise<ICVLValueModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/model/cvls/${cvlId}/values/${key}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as ICVLValueModel);
                        }
                    });
            });
        });
    }
    
    public deleteDeleteCvlValue( cvlId?:string , key?:string ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .delete(`${this.baseUrl}/api/v1.0.0/model/cvls/${cvlId}/values/${key}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public getGetAllSpecificationTemplates():Promise<Array<IEntitySummaryModel>>{
        return new Promise<Array<IEntitySummaryModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/specificationtemplates`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IEntitySummaryModel>);
                        }
                    });
            });
        });
    }
    
    public getGetSpecificationTemplatesields( templateId?:number ):Promise<Array<ISpecificationFieldTypeModel>>{
        return new Promise<Array<ISpecificationFieldTypeModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/model/specificationtemplates/${templateId}/fieldtypes`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISpecificationFieldTypeModel>);
                        }
                    });
            });
        });
    }
}



