/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */

import * as request from "superagent";
import { ISyndicationModel } from "./models"
import { requestWithToken } from '../adalConfig';

export class SyndicateHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public getSyndications():Promise<Array<ISyndicationModel>>{
        return new Promise<Array<ISyndicationModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/syndications`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISyndicationModel>);
                        }
                    });
            });
        });
    }
    
    public postRunSyndicate( syndicationId?:number ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/syndications/${syndicationId}:run`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
}



