import { observable, computed, autorun, action } from 'mobx';
import * as request from "superagent";
import { requestWithToken } from '../adalConfig';

export default class UserStore {
    @observable public fullName: string | undefined;

    constructor() {
        requestWithToken((tokenError, token) => {
            request
                .get(`${window.location.protocol}//${window.location.host}/UserApi/fullname`)
                .set('Authorization', `bearer ${token}`)
                .end((error, res) => {
                    if (!error) {
                        this.setFullName(res.text as string);
                    }
                });
        });
    }
  

    @action public setFullName(name: string) {
        this.fullName = name;
    }


}