import * as React from "react";
import QueryContext, { IQueryContextValue } from "./QueryContext";
import { IQueryModel } from "../../InRiverApiClient/models";
import * as _ from 'underscore';

export interface IFilterSettings {
    query?: IQueryModel
    queryChanged?: (IQueryModel) => void
}

export function WrapQueryContext<T>(WrappedComponent: React.ComponentType<T & IQueryContextValue>) {
    const decorated = class extends React.Component<T & IFilterSettings, {}, IQueryContextValue> {
        public render() {
            return <QueryContext.Consumer>{
                context => {
                    const wrappedContext = _.clone(context);
                    wrappedContext.query = this.props.query || context.query;
                    wrappedContext.updateQuery = (q) => { context.updateQuery(q); this.queryChanged(q); }
                    return <WrappedComponent {...this.props as T} {...wrappedContext} />
                }
            }
            </QueryContext.Consumer>
        }

        public componentDidMount() {
            const context = this.context as IQueryContextValue
            if (this.props.query && !_.isEqual(this.props.query, context.query)) {
                context.updateQuery(this.props.query);
            }
        }

        public componentDidUpdate(prevProps: IFilterSettings) {
            const context = this.context as IQueryContextValue
            if (this.props.query && !_.isEqual(prevProps.query, this.props.query) && !_.isEqual(this.props.query, context.query)) {
                context.updateQuery(this.props.query);
            }
        }

        private queryChanged(query: IQueryModel) {
            if (this.props.queryChanged) {
                this.props.queryChanged(query);
            }
        }
    }
    decorated.contextType = QueryContext;


    return decorated;
}