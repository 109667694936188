import * as React from 'react';
import { Filter } from '../components/Filtering/Filter';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores'
import CvlFilter from '../components/Filtering/CvlFilter';
import EntityTypeFilter from '../components/Filtering/EntityTypeFilter';
import Entities from '../components/EntityList/Entities';
import EntityList from '../components/EntityList/EntityList';
import ShowMoreButton from '../components/EntityList/ShowMoreButton';
import { ProductCard } from '../components/ProductCard';
import MultiSelectCvlFilter from '../components/Filtering/MultiSelectCvlFilter';
import FilterGroup from '../components/Filtering/FilterGroup';
import HierarchyCvlFilter from '../components/Filtering/HierarchyCvlFilter';
import FieldValueFilter from '../components/Filtering/FieldValueFilter';
import { ICVLValueModel } from '../InRiverApiClient/models';
import { Loader } from '../components/Loader';


export interface ICatalogProps {
    hide: boolean
}

function filterCvlValues(values: Map<string, ICVLValueModel[]>) {
    const result = new Map<string, ICVLValueModel[]>();
    values.forEach((value, key) => {
        if (["Model", "ModelVariant"].includes(key)) {
            result.set(key, value.filter(x => x.key !== `All${x.parentKey}`));
        } else {
            result.set(key, value);
        }
    })

    return result;
}

export const Catalog = observer((props: ICatalogProps) => {
    const { modelStore, searchStore, catalogStore } = useStores();

    if (modelStore.ready) {
        return <div className={`product - category__container ${props.hide ? "hidden" : ""}`} >
            <div className="product-category__filter">
                <div className="button-close-filter-off-canvas mb-md">
                    <button type="reset">Reset</button>
                    <div className="filter__header-title">Filter</div>
                    <button type="button">Done</button>
                </div>

                <div className="filter">
                    <div className="filter__content">
                        <h2 className="filter--heading u-border-bottom pb-xs">Services</h2>
                        <Filter onQueryChanged={q => searchStore.setQuery(q)} cvls={modelStore.cvls} cvlValues={filterCvlValues(modelStore.cvlValues)} entityTypes={modelStore.entityTypes} language="en" >
                            <EntityTypeFilter entityTypeId="Service" />
                            <FieldValueFilter fieldTypeId={"ServiceApprovedForInternal"} operator={"IsTrue"} />
                            {searchStore.searchString && (/_/.test(searchStore.searchString) ?
                                <FieldValueFilter fieldTypeId={"ServiceSAPID"} operator={"Contains"} value={searchStore.searchString} /> :
                                <FieldValueFilter fieldTypeId={"ServiceCommercialName"} operator={"Contains"} value={searchStore.searchString} />)}
                            <MultiSelectCvlFilter label="Business Area" fieldTypeId="ServiceBusinessArea" query={catalogStore.businessAreaQuery} queryChanged={q => catalogStore.setBusinessAreaQuery(q)} />
                            <HierarchyCvlFilter fieldTypeIds={["ServiceCommercialCategory", "ServiceCommercialSubCategory"]} query={catalogStore.categoryQuery} queryChanged={q => catalogStore.setCategoryQuery(q)} />
                            <div className="mb-md">
                                <div className="filter__content-header">Turbine</div>
                                <FilterGroup>
                                    <div className="select-block">
                                        <CvlFilter fieldTypeId="ServiceBrands" allLabel="All brands" query={catalogStore.brandQuery} queryChanged={q => catalogStore.setBrandQuery(q)} />
                                    </div>
                                    <div className="select-block">
                                        <CvlFilter fieldTypeId="ServiceModels" allLabel="All turbines" query={catalogStore.turbineQuery} queryChanged={q => catalogStore.setTurbineQuery(q)} />
                                    </div>
                                    <div className="select-block">
                                        <CvlFilter fieldTypeId="ServiceModelVariants" allLabel="All MK versions" query={catalogStore.modelQuery} queryChanged={q => catalogStore.setModelQuery(q)} />
                                    </div>
                                </FilterGroup>
                            </div>
                            <div className="mb-md">
                                <div className="filter__content-header">SBU availability</div>
                                <div className="select-block">
                                    <CvlFilter label="SBU availability" fieldTypeId="ServiceSalesBusinessUnit" allLabel="All" query={catalogStore.sbuQuery} queryChanged={q => catalogStore.setSbuQuery(q)} />
                                </div>
                            </div>
                            <div className="mb-md">
                                <div className="filter__content-header">Country availability</div>
                                <div className="select-block">
                                    <CvlFilter label="Country availability" fieldTypeId="ServiceCountries" allLabel="All" query={catalogStore.countryQuery} queryChanged={q => catalogStore.setCountryQuery(q)} />
                                </div>
                            </div>
                            <div className="mb-md">
                                <div className="filter__content-header">Development stage</div>
                                <div className="select-block">
                                    <CvlFilter label="Development stage" fieldTypeId="ServiceDevelopmentStage" allLabel="All" query={catalogStore.developmentStageQuery} queryChanged={q => catalogStore.setDevelopmentStageQuery(q)} />
                                </div>
                            </div>


                        </Filter>
                    </div>
                </div>
            </div>

            <div className="product-category__content">
                <div className="product-category__header">
                    <div className="content-search-result__total-results">
                        <div className="content-search-result__total-results-headline">
                            Showing {searchStore.resultEntityIds ? searchStore.resultEntityIds.length : 0} results
                        </div>
                    </div>

                    <div className="button-toggle-filter-off-canvas">
                        <button type="button">Filter</button>
                    </div>
                </div>

                <div className="product-search__filters">
                    <ul className="tag-list search-filter__tag-list">
                        {searchStore.searchString &&
                            <li>
                                <span>"{searchStore.searchString}"</span>
                                <button type="button" onClick={() => searchStore.setSearchString("")}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.businessAreaQueryValue &&
                            <li>
                                <span>{catalogStore.businessAreaQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setBusinessAreaQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.categoryQueryValue &&
                            <li>
                                <span>{catalogStore.categoryQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setCategoryQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.brandQueryValue &&
                            <li>
                                <span>{catalogStore.brandQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setBrandQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.turbineQueryValue &&
                            <li>
                                <span>{catalogStore.turbineQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setTurbineQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.modelQueryValue &&
                            <li>
                                <span>{catalogStore.modelQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setModelQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.sbuQueryValue &&
                            <li>
                                <span>{catalogStore.sbuQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setSbuQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.countryQueryValue &&
                            <li>
                                <span>{catalogStore.countryQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setCountryQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                        {catalogStore.developmentStageQueryValue &&
                            <li>
                                <span>{catalogStore.developmentStageQueryValue}</span>
                                <button type="button" onClick={() => catalogStore.setDevelopmentStageQuery({})}>
                                    <span>Delete</span>
                                </button>
                            </li>
                        }
                    </ul>
                </div>

                <EntityList ids={searchStore.resultEntityIds} language="en" >
                    <div className="product-overview product-grid">
                        <Entities fields={["ServiceCommercialName", "ServiceBusinessArea", "ServiceCommercialCategory", "ServiceScope"]} onError={error => catalogStore.setError(true)} >
                            {(s) => <ProductCard {...s} />}
                        </Entities>
                    </div>

                    <div className="product-overview__load-more-button">
                        <ShowMoreButton label="Show More" initialNumberOfShownEntities={10} increment={5} />
                    </div>
                </EntityList>
            </div>
        </div>;

    }
    else {

        return <div className="py-md px-sm text-center">
               <Loader />
            </div>
    }
});
