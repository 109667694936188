import { observable, computed, autorun, action } from 'mobx';
import { IEntityBundleModel, IFieldSummaryModel, ICVLValueModel, IQueryModel, IDataCriterionModel, IEntitySummaryModel, ISystemCriterionModel, IEntityListModel, IFieldValueModel } from '../InRiverApiClient/models';
import { IContact } from '../model/Contact';
import { IDetailField } from '../model/DetailField';
import { ICVLValueModelExtended } from '../model/CVLValueModelExtended';
import { IDocumentationObject } from '../model/DocumentationObject';
import { ICompatibleTurbineObject } from '../model/CompatibleTurbineObject';
import { entityHttpSvc, modelHttpSvc, queryHttpSvc } from "../services/InRiverApiClient"
import { IServiceInfoObject } from '../model/ServiceInfoObject';
import { IServiceTopObject } from '../model/ServiceTopObject';
import { recordEvent } from './TelemetryCollector';

export class DetailsStore {
    @observable public entityId: number = -1;
    @observable public language: string = "en";
    @observable public entityBundle: IEntityBundleModel | null;
    @observable public cvlValues: ICVLValueModelExtended[] = [];
    @observable public modelVariants = new Map<string, IEntityBundleModel>();
    @observable public error: boolean = false;
    @observable public isLoaded: boolean = false;
    @observable public contactMail: string = "";
    public fallbackLanguage: string = "en";
    public fallbackPrefix: string = "*(From English)* ";

    @observable public selectedDocumentationBrandKey: string = "";
    @observable public selectedDocumentationModelKey: string = "";
    @observable public selectedDocumentationModelVariantKey: string = "";
    


    constructor() {
        // Get all needed CVL values from modle and add to cvlValues.
        modelHttpSvc.getGetAllCvlValues("SalesBusinessUnits").then(model => this.addToCvlValues(model, "SalesBusinessUnits"));
        modelHttpSvc.getGetAllCvlValues("Segment").then(model => this.addToCvlValues(model, "Segment"));
        modelHttpSvc.getGetAllCvlValues("Category").then(model => this.addToCvlValues(model, "Category"));
        modelHttpSvc.getGetAllCvlValues("Scope").then(model => this.addToCvlValues(model, "Scope"));
        modelHttpSvc.getGetAllCvlValues("BusinessArea").then(model => this.addToCvlValues(model, "BusinessArea"));
        modelHttpSvc.getGetAllCvlValues("Country").then(model => this.addToCvlValues(model, "Country"));
        modelHttpSvc.getGetAllCvlValues("CommercialCategory").then(model => this.addToCvlValues(model, "CommercialCategory"));
        modelHttpSvc.getGetAllCvlValues("CommercialSubCategory").then(model => this.addToCvlValues(model, "CommercialSubCategory"));
        modelHttpSvc.getGetAllCvlValues("ProjectType").then(model => this.addToCvlValues(model, "ProjectType"));
        modelHttpSvc.getGetAllCvlValues("DevelopmentStage").then(model => this.addToCvlValues(model, "DevelopmentStage"));
        modelHttpSvc.getGetAllCvlValues("DocumentationSubtype").then(model => this.addToCvlValues(model, "DocumentationSubtype"));
        modelHttpSvc.getGetAllCvlValues("Brand").then(model => this.addToCvlValues(model, "Brand"));
        modelHttpSvc.getGetAllCvlValues("Model").then(model => this.addToCvlValues(model, "Model"));
        modelHttpSvc.getGetAllCvlValues("ModelVariant").then(model => this.addToCvlValues(model, "ModelVariant"));
        modelHttpSvc.getGetAllCvlValues("Industry").then(model => this.addToCvlValues(model, "Industry"));

        this.getContactMail();

    }

    @action public setEntityId(id: number) {

        this.setLoadedStatus(false);
        this.entityId = id;
        this.modelVariants = new Map();
        this.entityBundle = null;
        if (id === -1) {
            return;
        }
        
        recordEvent("Service",id.toString())
        entityHttpSvc.getGetEntityBundle(id, undefined, "outbound", undefined)
            .then(
                bundle => this.setEntityBundle(bundle),
                error => this.setError(true)
            ).then(() => this.setLoadedStatus(true));
    }

    @computed public get serviceInfoData(): IServiceInfoObject {

        return {
            externalDescription: this.getStringField("ServicePublicDescription", this.entityBundle),
            name: this.getStringField("ServiceCommercialName", this.entityBundle),
            sapMaterialID: this.getStringField("ServiceSAPID", this.entityBundle),
            developmentStage: this.getCvlValuesField("ServiceDevelopmentStage", this.entityBundle)[0] ? this.getCvlValuesField("ServiceDevelopmentStage", this.entityBundle)[0] : "",
            imageUrls: this.getImagesFromResources(),
            keyDetails: this.generateKeyDetailList(),
            scope: this.getField("ServiceScope", this.entityBundle) ? (this.getField("ServiceScope", this.entityBundle) as IFieldSummaryModel).value : ""
        } as IServiceInfoObject;
    }

    @computed public get serviceTopData(): IServiceTopObject {

        return {

            sapMaterialID: this.getStringField("ServiceSAPID", this.entityBundle)

        } as IServiceTopObject;
    }

    @computed public get relatedServices(): number[] {

        const list: number[] = [];

        if (!this.entityBundle) {
            return [];
        }
        if (!this.entityBundle.outbound) {
            return [];
        }
        const links = this.entityBundle.outbound.filter(f => f.linkTypeId === "ServiceService");
        if (!links) {
            return [];
        }

        links.filter(link => link && link.summary && link.summary.id && link.fields).map((entity) => {

            const isApprovedForExport = this.getStringField("ServiceApprovedForInternal", entity);

            if (isApprovedForExport === "True") {
                list.push((entity.summary as IEntitySummaryModel).id as number);
            }
        });

        return list;
    }

    @computed public get compatibleTurbinesList(): ICompatibleTurbineObject[] {

        if (this.entityBundle === null) {
            return [];
        }
        const resultList: ICompatibleTurbineObject[] = [];
        const modelVariantField = this.getField("ServiceModelVariants", this.entityBundle);
        if (!modelVariantField) {
            return [];
        }
        const modelField = this.getField("ServiceModels", this.entityBundle);
        if (!modelField || !modelField.value) {
            return [];
        }
        const modelKeys: string[] = modelField.value.toString().split(",");

        if (!modelVariantField.value) {

            // If there is no value in this field, it must be because only 'All-something' is selected in model
            modelKeys.forEach((key) => {

                if (key.includes("All")) {

                    const brandFromKey = key.substring(3);
                    resultList.push({
                        brand: brandFromKey,
                        model: "All",
                        mark: "",
                        mw: "",
                    } as ICompatibleTurbineObject);

                }
                else {
                    // There is a data error - do nothing.
                }
            })
        }
        else {

            // There are values in model variant field

            // Still check if there are any 'All' values in models, if there are add them to the list
            modelKeys.forEach((key) => {

                if (key.includes("All")) {

                    const brandFromKey = key.substring(3);
                    resultList.push({
                        brand: brandFromKey,
                        model: "All",
                        mark: "",
                        mw: "",
                    } as ICompatibleTurbineObject);
                }
            });

            // Now look at the model variant data
            const modelVariantKeys: string[] = modelVariantField.value.toString().split(",");

            modelVariantKeys.forEach((key) => {

                if (key.includes("All")) {

                    // One of the model values is all-something, handle this
                    const subkey = key.substring(3); // Remove "All" from string
                    this.cvlValues.filter(cvl => cvl.cvlId === "Brand").forEach((cvl) => {

                        // Find a brand that matches the one in the string
                        const cvlKey = cvl.model.key;
                        if (cvlKey) {
                            if (subkey.includes(cvlKey)) {

                                // If brand matches string, set that as brand, and model must be the rest of the string
                                const modelFromKey: string = subkey.substring(cvlKey.length);
                                resultList.push({
                                    brand: cvlKey,
                                    model: modelFromKey,
                                    mark: "All",
                                    mw: "",
                                } as ICompatibleTurbineObject);
                            }
                        }
                    });
                }
                else {

                    // Find the corresponding ModelVariant entity from Map
                    const entity = this.modelVariants.get(key);

                    if (entity) {

                        // Create compatible turbine object from the entity values
                        const brandValue = this.getCvlValuesField("ModelVariantBrand", entity);
                        const modelValue = this.getStringField("ModelVariantModel", entity);
                        const mkValue = this.getStringField("ModelVariantMKVersion", entity);
                        const mwValue = this.getStringField("ModelVariantNominalPower", entity);

                        resultList.push({
                            brand: brandValue[0],
                            model: modelValue,
                            mark: mkValue,
                            mw: mwValue,
                        } as ICompatibleTurbineObject);
                    }
                }
            });
        }

        return resultList;
    }

    @action public setEntityBundle(bundle: IEntityBundleModel) {

        // Set entity bundle
        this.entityBundle = bundle;

        // Also get model variant entities needed to show the turbine compatibability
        const modelVariantField = this.getField("ServiceModelVariants", this.entityBundle);
        if (!modelVariantField || !modelVariantField.value) {
            return;
        }
        const modelVariantKeys: string[] = modelVariantField.value.toString().split(",");

        modelVariantKeys.forEach((key) => {

            if (key.includes("All")) {

                // Do nothing

            }
            else {

                // Find the corresponding ModelVariant entity
                const dataCriteria = {} as IDataCriterionModel;
                dataCriteria.fieldTypeId = "ModelVariantModelVariantCVLKey";
                dataCriteria.operator = "Equal";
                dataCriteria.value = key;
                const query = {} as IQueryModel;
                query.dataCriteria = [dataCriteria];

                queryHttpSvc.postQuery(query).then((result) => {
                    if (result && result.count && result.count > 0 && result.entityIds) {

                        const foundMVEntityId = result.entityIds[0];

                        // Get the ModelVariant entity
                        entityHttpSvc.getGetEntityBundle(foundMVEntityId).then((entity) => {

                            this.addModelVariant(entity, key);
                        });
                    }
                });
            }
        });

    }

    @action public addModelVariant(modelVariant: IEntityBundleModel, key: string) {
        this.modelVariants.set(key, modelVariant);
    }

    @action public setLanguage(lang: string) {
        this.language = lang;
    }

    @computed public get serviceName(): string | undefined {
        return this.getStringField("ServiceCommercialName", this.entityBundle);
    }

    @computed public get benefits(): string {
        return this.getStringField("ServiceBenefits", this.entityBundle);
    }

    @computed public get internalDescription(): string | undefined {
        return this.getStringField("ServiceInternalDescription", this.entityBundle);
    }

    @computed public get externalDescription(): string | undefined {
        return this.getStringField("ServicePublicDescription", this.entityBundle);
    }

    @computed public get contacts(): IContact[] {

        const globalContact = this.getStringField("ServiceGlobalContact", this.entityBundle)
        const nceContact = this.getStringField("ServiceNCEContact", this.entityBundle)
        const medContact = this.getStringField("ServiceMEDContact", this.entityBundle)
        const latamContact = this.getStringField("ServiceLATAMContact", this.entityBundle)
        const apacContact = this.getStringField("ServiceAPACContact", this.entityBundle)
        const ameContact = this.getStringField("ServiceAMEContact", this.entityBundle)
        const techContact = this.getStringField("ServiceTechnicalResponsible", this.entityBundle)

        return [{ area: "Global contact", person: globalContact } as IContact, { area: "NCE contact", person: nceContact } as IContact, { area: "MED contact", person: medContact } as IContact,
            { area: "LATAM contact", person: latamContact } as IContact, { area: "APAC contact", person: apacContact } as IContact, { area: "AME contact", person: ameContact } as IContact,
        { area: "Technical responsible", person: techContact } as IContact];
    }

    @computed public get details(): IDetailField[] {

        const commercialName = this.getStringField("ServiceCommercialName", this.entityBundle);
        const scope = this.getCvlValuesField("ServiceScope", this.entityBundle);
        const sapMaterialID = this.getStringField("ServiceSAPID", this.entityBundle)
        const industry = this.getCvlValuesField("ServiceIndustry", this.entityBundle);
        const businessArea = this.getCvlValuesField("ServiceBusinessArea", this.entityBundle);
        const segment = this.getCvlValuesField("ServiceSegment", this.entityBundle);
        const category = this.getCvlValuesField("ServiceCategory", this.entityBundle);
        const commercialCategory = this.getCvlValuesField("ServiceCommercialCategory", this.entityBundle);
        const commercialSubCategory = this.getCvlValuesField("ServiceCommercialSubCategory", this.entityBundle);
        const developmentStage = this.getCvlValuesField("ServiceDevelopmentStage", this.entityBundle);
        const approvedForExternalPublication = this.getStringField("ServiceApprovedForExternal", this.entityBundle);

        return [
            { name: "Commercial name", value: commercialName } as IDetailField,
            { name: "Scope", value: scope[0] } as IDetailField,
            { name: "SAP material ID", value: sapMaterialID } as IDetailField,
            { name: "Industry", value: industry[0] } as IDetailField,
            { name: "Business area", value: businessArea[0] } as IDetailField,
            { name: "Segment", value: segment[0] } as IDetailField,
            { name: "SAP Classification", value: category[0] } as IDetailField,
            { name: "Commercial category", value: commercialCategory[0] } as IDetailField,
            { name: "Commercial subcategory", value: commercialSubCategory[0] } as IDetailField,
            { name: "Development stage", value: developmentStage[0] } as IDetailField,
            { name: "Approved for ShopVestas", value: approvedForExternalPublication } as IDetailField];
    }

    @computed public get countries(): string[] {
        const countries = this.getCvlValuesField("ServiceCountries", this.entityBundle);
        if (!countries) {
            return [];
        }

        return countries;
    }

    @computed public get sbus(): string[] {
        const sbus = this.getCvlValuesField("ServiceSalesBusinessUnit", this.entityBundle);
        if (!sbus) {
            return [];
        }
        return sbus;
    }

    @computed public get technicalDocumentation(): IDocumentationObject[] {

        return this.getDocumentation("ServiceTechnicalDocumentation");
    }

    @computed public get commercialDocumentation(): IDocumentationObject[] {
        return this.getDocumentation("ServiceCommercialDocumentation");
    }

    @computed public get filteredCommercialDocumentation(): IDocumentationObject[] {
        return this.filterOnSelectedKeys(this.commercialDocumentation);
    }


    @computed public get filteredTechnicalDocumentation(): IDocumentationObject[] {
        return this.filterOnSelectedKeys(this.technicalDocumentation);
    }

    @action public setError(error: boolean) {
        this.error = error;
    }

    @action public setLoadedStatus(status: boolean) {


        this.isLoaded = status;
    }

    @computed public get allDocumentationBrands(): ICVLValueModel[] {

        const allBrands: ICVLValueModel[] = [];
        this.commercialDocumentation.concat(this.technicalDocumentation).map(doc => doc.brands.map(newBrand => {

            if (allBrands.filter(existingBrand => existingBrand.key === newBrand.key).length < 1) {
                allBrands.push(newBrand);
            }
        }));

        return allBrands;
    }

    @computed public get filteredDocumentationModels(): ICVLValueModel[] {

        const allModels: ICVLValueModel[] = [];
        this.commercialDocumentation.concat(this.technicalDocumentation).map(doc => doc.models.map(newModel => {

            if (allModels.filter(existingModel => existingModel.key === newModel.key).length < 1 && (newModel.key && !newModel.key.includes("All"))) {

                allModels.push(newModel);
            }
        }));

        return allModels.filter(model => model.parentKey === this.selectedDocumentationBrandKey);
    }

    @computed public get filteredDocumentationModelVariants(): ICVLValueModel[] {

        const allModelVariants: ICVLValueModel[] = [];
        this.commercialDocumentation.concat(this.technicalDocumentation).map(doc => doc.modelVariants.map(newModelVariant => {

            if (allModelVariants.filter(existingModelVariant => existingModelVariant.key === newModelVariant.key).length < 1 && (newModelVariant.key && !newModelVariant.key.includes("All"))) {
                allModelVariants.push(newModelVariant);
            }
        }));

        return allModelVariants.filter(model => model.parentKey === this.selectedDocumentationModelKey);
    }

    @action public setSelectedDocumentationBrandKey(key: string) {
        this.selectedDocumentationBrandKey = key;
        this.selectedDocumentationModelKey = "Default";
        this.selectedDocumentationModelVariantKey = "Default";
    }

    @action public setSelectedDocumentationModelKey(key: string) {
        this.selectedDocumentationModelKey = key;
        this.selectedDocumentationModelVariantKey = "Default";
    }

    @action public setSelectedDocumentationModelVariantKey(key: string) {
        this.selectedDocumentationModelVariantKey = key;
    }

    private getCvlValuesField(fieldTypeId: string, entityBundle: IEntityBundleModel | null): string[] {
        const field = this.getField(fieldTypeId, entityBundle);
        if (!field) {
            return [];
        }
        const fieldValue = field.value;
        if (!fieldValue) {
            return [];
        }
        const keys = fieldValue.toString();
        if (!keys) {
            return [];
        }

        const keyArray = keys.split(",");
        const cvlId = field.cvlId;
        if (!cvlId) {
            return [];
        }

        const cvlValues = keyArray.map(key => {
            const cvlValue = this.cvlValues.find(cvl => cvl.cvlId === cvlId && cvl.model.key === key);
            if (cvlValue) {

                switch (cvlValue.localeString) {
                    case true: return cvlValue.model.value[this.language].toString();
                    case false: return cvlValue.model.value.toString();
                }

            }
            return "";
        })

        return cvlValues;
    }

    private GetCVLValueModelsField(fieldTypeId: string, entityBundle: IEntityBundleModel | null): ICVLValueModel[] {
        const field = this.getField(fieldTypeId, entityBundle);
        if (!field) {
            return [];
        }
        const fieldValue = field.value;
        if (!fieldValue) {
            return [];
        }
        const keys = fieldValue.toString();
        if (!keys) {
            return [];
        }

        const keyArray = keys.split(",");

        const cvlId = field.cvlId;
        if (!cvlId) {
            return [];
        }

        const cvlValues = keyArray.map(key => {
            const cvlValue = this.cvlValues.find(cvl => cvl.cvlId === cvlId && cvl.model.key === key);
            if (cvlValue) {

                return {key: cvlValue.model.key, value: cvlValue.model.value, parentKey: cvlValue.model.parentKey} as ICVLValueModel;

            }
            return "";
        })

        return cvlValues;
    }

    private addToCvlValues(model: ICVLValueModel[], CVLId: string, localeStringParam = false) {

        const arrayToAdd: ICVLValueModelExtended[] = model.map(cvlValue => {

            return { model: cvlValue, cvlId: CVLId, localeString: localeStringParam } as ICVLValueModelExtended;
        });

        this.cvlValues = this.cvlValues.concat(arrayToAdd);
    }

    private getDataEntity(queryResult: IEntityListModel) {

        if (queryResult.entityIds !== undefined && queryResult.entityIds.length !== 0) {

            const entityId: number = queryResult.entityIds[0]
            entityHttpSvc.getGetFieldValues(entityId, "DataEntityISCContactMail").then(result => this.setContactEmail(result))
            return;
        }

        this.setError(true);
    }

    private setContactEmail(fieldValues: IFieldValueModel[]) {

        const value = fieldValues[0].value;
        this.contactMail = value;
    }

    private getContactMail() {

        const systemCriteriaModel: ISystemCriterionModel = {

            type: "EntityTypeId",
            operator: "Equal",
            value: "DataEntity"
        }

        const query: IQueryModel = {

            systemCriteria: [systemCriteriaModel]
        }

        queryHttpSvc.postQuery(query).then((result) => this.getDataEntity(result));
    }

    private filterOnSelectedKeys(data: IDocumentationObject[]): IDocumentationObject[] {

        return data.filter(doc => {

            if (doc.brands.length < 1) {
                return true;
            }

            if (this.selectedDocumentationModelVariantKey !== "Default") {

                if (doc.modelVariants.some(mv => (mv.key && mv.key === this.selectedDocumentationModelVariantKey) || (mv.key && mv.key === "All" + this.selectedDocumentationModelKey))
                    || doc.models.some(model => model.key === "All" + this.selectedDocumentationBrandKey)) {
                    return true;
                }
                return false;

            }
            else if (this.selectedDocumentationModelKey !== "Default") {

                if (doc.models.some(model => model.key === this.selectedDocumentationModelKey || (model.key && model.key === "All" + this.selectedDocumentationBrandKey))) {
                    return true;
                }
                return false;

            }
            else if (this.selectedDocumentationBrandKey !== "Default") {

                if (doc.brands.some(brand => brand.key === this.selectedDocumentationBrandKey)) {
                    return true;
                }
                return false;
            }
            else {
                return false;
            }
        })
    }

    private getStringField(fieldTypeId: string, entityBundle: IEntityBundleModel | null): string {
        const field = this.getField(fieldTypeId, entityBundle);
        if (!field) {
            return "";
        }

        switch (field.fieldDataType) {
            case "LocaleString": return this.getStringValue(field);
            case "String": return field.value as string;
            case "Boolean": {
                if (typeof (field.value) === 'undefined' || field.value === null) {
                    return "";
                }
                const bool = field.value as boolean;
                return bool ? "True" : "False";
            };
        }
        return "";
    }

    private getField(fieldTypeId: string, entityBundle: IEntityBundleModel | null): IFieldSummaryModel | undefined {
        if (!entityBundle) {
            return undefined;
        }
        if (!entityBundle.fields) {
            return undefined;
        }
        const field = entityBundle.fields.filter(f => f.fieldTypeId === fieldTypeId);
        if (field && field.length === 1) {
            return field[0];
        }

        return undefined;
    }

    private getDocumentation(linkTypeId: string): IDocumentationObject[] {

        const list: IDocumentationObject[] = [];

        if (!this.entityBundle) {
            return [];
        }
        if (!this.entityBundle.outbound) {
            return [];
        }
        const links = this.entityBundle.outbound.filter(f => f.linkTypeId === linkTypeId);
        if (!links) {
            return [];
        }

        links.filter(entity => entity.fields).map((entity) => {
            if (entity.fields) {
                const nameValue = this.getStringField("DocumentationName", entity);
                const linkValue = this.getStringField("DocumentationLink", entity);
                const subTypeValue = this.getCvlValuesField("DocumentationSubtype", entity);
                const languages = this.getField("DocumentationLanguages", entity);
                const brandsValue = this.GetCVLValueModelsField("DocumentationBrands", entity);
                const modelsValue = this.GetCVLValueModelsField("DocumentationModels", entity);
                const modelVariantsValue = this.GetCVLValueModelsField("DocumentationModelVariant", entity);

                let languageString: string = "";
                if (languages) {
                    languageString = languages && languages.value ? languages.value.toString().split(",").join(" ") : "";
                }

                list.push({ subType: subTypeValue[0], name: nameValue, link: linkValue, language: languageString.toUpperCase(), brands: brandsValue, models: modelsValue, modelVariants: modelVariantsValue } as IDocumentationObject);
            }
        });

        return list;
    }

    private getImagesFromResources(): string[] {

        if (!this.entityBundle) {
            return [];
        }
        if (!this.entityBundle.outbound) {
            return [];
        }
        const links = this.entityBundle.outbound.filter(f => f.linkTypeId === "ServiceResource");
        if (!links) {
            return [];
        }

        return links
            .filter(resource => resource && resource.summary && resource.summary.resourceUrl)
            .map(resource => (resource.summary as IEntitySummaryModel).resourceUrl as string);

    }

    private getStringValue(field: IFieldSummaryModel): string {

        const currentLanguageString = field.value[this.language] ? field.value[this.language] : "";

        if (currentLanguageString === "") {

            const defaultLanguageString = field.value[this.fallbackLanguage] ? field.value[this.fallbackLanguage] : "";

            if (defaultLanguageString !== "") {

                return this.fallbackPrefix + defaultLanguageString;
            }
        }

        return currentLanguageString;
    }

    private generateKeyDetailList(): IDetailField[] {

        const scope = this.getCvlValuesField("ServiceScope", this.entityBundle)[0] ? this.getCvlValuesField("ServiceScope", this.entityBundle)[0] : "";
        const businessArea = this.getCvlValuesField("ServiceBusinessArea", this.entityBundle);
        const commercialCategory = this.getCvlValuesField("ServiceCommercialCategory", this.entityBundle);
        const commercialSubCategory = this.getCvlValuesField("ServiceCommercialSubCategory", this.entityBundle);
        const approvedForExternalPublication = this.getStringField("ServiceApprovedForExternal", this.entityBundle);
        const sapMaterialID = this.getStringField("ServiceSAPID", this.entityBundle);
        const developmentStage = this.getCvlValuesField("ServiceDevelopmentStage", this.entityBundle)[0] ? this.getCvlValuesField("ServiceDevelopmentStage", this.entityBundle)[0] : "";

        return [{ name: "Scope", value: scope } as IDetailField,
        { name: "SAP material ID", value: sapMaterialID } as IDetailField,
        { name: "Development stage", value: developmentStage } as IDetailField,
        { name: "Business area", value: businessArea[0] } as IDetailField,
        { name: "Commercial category", value: commercialCategory[0] } as IDetailField,
        { name: "Commercial subcategory", value: commercialSubCategory[0] } as IDetailField,
        { name: "Approved for ShopVestas", value: approvedForExternalPublication.replace("True", "Yes").replace("False", "No") } as IDetailField];
    }
}

