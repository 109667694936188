import * as React from 'react';
import EntityListContext from './EntityListContext';
import * as _ from "underscore";

export interface IEntityListProps {
    ids: number[],
    language: string
}

export interface IEntityListState {
    visibleIds: number[]
}

export default class EntityList extends React.Component<IEntityListProps, IEntityListState> {
    
    public render() {
        const allIds = this.props.ids;
        const visibleIds = this.state && this.state.visibleIds ? this.state.visibleIds : [] as number[];
        const setVisibleIds = (ids: number[]) => this.setVisibleIds(ids);
        const language = this.props.language;

        return <EntityListContext.Provider value={{ allIds, visibleIds, setVisibleIds, language }} >
            {this.props.children}
        </EntityListContext.Provider>
    }

    private setVisibleIds(ids: number[]) {
        if (!this.state || !_.isEqual(ids, this.state.visibleIds)) {
            this.setState({ visibleIds: ids });
        }
    }
}