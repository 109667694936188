import * as React from "react";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel, IDataCriterionModel } from "../../InRiverApiClient/models";
import { Filter } from "./Filter";
import { WrapQueryContext } from "./QueryContextWrapper";
import { IQueryContextValue } from "./QueryContext";

class FilterGroup extends React.Component<{children: any} & IQueryContextValue> {
    public render() {
        return <Filter cvls={this.props.cvls}
            cvlValues={this.props.cvlValues}
            entityTypes={this.props.entityTypes}
            onQueryChanged={q => this.props.updateQuery(q)}
            language={this.props.language}>
                    {this.props.children}
                </Filter>
    }
}

export default WrapQueryContext(FilterGroup);