import * as React from 'react';
import { observer } from 'mobx-react';

export const Loader = observer(() => {

    return <div className="loader alternative">
        <span/>
        <span/>
        <span/>
    </div>
});
