/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */

import * as request from "superagent";
import { IImageConfigurationDetailsModel,IRoleModel,ISegmentModel } from "./models"
import { requestWithToken } from '../adalConfig';

export class SystemHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public getGetAllImageConfigurations():Promise<Array<string>>{
        return new Promise<Array<string>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/system/imageconfigurations`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<string>);
                        }
                    });
            });
        });
    }
    
    public getGetImageConfigurationDetails():Promise<IImageConfigurationDetailsModel>{
        return new Promise<IImageConfigurationDetailsModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/system/imageconfigurationdetails`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IImageConfigurationDetailsModel);
                        }
                    });
            });
        });
    }
    
    public getGetServerSettings( settingNames?:string ):Promise<any>{
        const params = {
            "settingNames":settingNames
        };
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/system/serversettings`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public getRoles( forUsername?:string ):Promise<Array<IRoleModel>>{
        const params = {
            "forUsername":forUsername
        };
        return new Promise<Array<IRoleModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/system/roles`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IRoleModel>);
                        }
                    });
            });
        });
    }
    
    public getSegments( forUsername?:string ):Promise<Array<ISegmentModel>>{
        const params = {
            "forUsername":forUsername
        };
        return new Promise<Array<ISegmentModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/system/segments`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<ISegmentModel>);
                        }
                    });
            });
        });
    }
}



