import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores';

export const SearchBar = observer(() => {
    const { searchStore, routingStore, catalogStore } = useStores();
    const onCatalog = routingStore.location.pathname === "/";

    return <form className="spareparts-header-search__form w-full md:w-auto pb-xs md:mr-sm"
        onSubmit={e => {
            e.preventDefault();
            if (!onCatalog) {
                routingStore.push("/")
                catalogStore.setBusinessAreaQuery({});
                catalogStore.setBrandQuery({});
                catalogStore.setTurbineQuery({});
                catalogStore.setModelQuery({});
                catalogStore.setSbuQuery({});
                catalogStore.setCountryQuery({});
                catalogStore.setDevelopmentStageQuery({});
            }
        }}>
        <div className="spareparts-header-search input-loading">
            <label htmlFor="search-input" className="hidden">Search</label>
            <input type="text" id="search-input" name="search" placeholder="Search by SAP material ID or name..."
                value={searchStore.searchString}
                onChange={e => searchStore.setSearchString(e.target.value)} />
                <button type="submit" className="button-desktop">Search</button>
                <button type="submit" className="button-mobile" />
        </div>
    </form>;

});