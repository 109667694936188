import * as React from "react";
import QueryContext, { IQueryContextValue } from "./QueryContext";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel } from "../../InRiverApiClient/models";
import { WrapQueryContext } from "./QueryContextWrapper";
import * as _ from "underscore";

export interface IEntityTypeFilterProps {
    entityTypeId: string
}

class EntityTypeFilter extends React.Component<IEntityTypeFilterProps & IQueryContextValue> {
    public componentDidMount() {
        this.updateQuery();
    }

    public componentDidUpdate() {
        this.updateQuery();
    }

    public render() {
        return null;
    }

    private updateQuery() {
        const newQuery = {
            systemCriteria: [
                {
                    type: "EntityTypeId",
                    operator: "Equal",
                    value: this.props.entityTypeId
                }
            ]
        } as IQueryModel;

        if (!_.isEqual(newQuery, this.props.query)) {
            this.props.updateQuery(newQuery);
        }
    }

    
}

export default WrapQueryContext(EntityTypeFilter);