import * as React from "react";
import { IQueryModel, IEntityTypeModel, ICVLModel, IFieldTypeModel, ICVLValueModel } from "../../InRiverApiClient/models";
import EntityListContext, { IEntityListContextValue } from "./EntityListContext";
import { WrapEntityListContext } from "./EntityListContextWrapper";

class ShowAll extends React.Component<IEntityListContextValue> {
    public componentDidMount() {
        if (this.props.setVisibleIds) {
            this.props.setVisibleIds(this.props.allIds);
        }
    }

    public render() {
        return null;
    }

    public componentDidUpdate(prevProps) {
        if (this.props.setVisibleIds) {
            this.props.setVisibleIds(this.props.allIds);
        }
    }
}

export default WrapEntityListContext<{}>(ShowAll);