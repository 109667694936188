import * as React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/UseStores';
import { IDocumentationObject } from '../model/DocumentationObject';


function openInNewTab(link: string) {
    window.open(
        link, "_blank"); 
}

function generateDocumentationSection(documentation: IDocumentationObject[]) {

    if (documentation.length > 0) {
        return documentation.map((document, index) =>
                <div className="o-flexlist__row" key={index}>
                    <div className="o-flexlist__item">{document.subType}</div>
                    <div className="o-flexlist__item">
                    <a href={document.link} className="flex items-center" target="_blank">
                            <span className="text-black mr-xs">{document.language}</span>
                            <span>{document.name}</span>
                            <i className="vestas-icon_file-pdf ml-xs" />
                        </a>
                    </div>
                </div>
            );
    }
    else {
        return <div className="o-flexlist__row">
            <div className="o-flexlist__item">
                <a href="#" className="flex items-center">
                    <span />
                </a>
            </div>
        </div>
    }
}

export const Documentation = observer(() => {

    const { detailsStore } = useStores();

    return <div className="c-product-related__section">
        <div className="c-product-related__header">
            <h2>Documentation</h2>
            <p className="text-sm mb-0">{detailsStore.technicalDocumentation.length + detailsStore.commercialDocumentation.length} documents available. Please select turbine details to display matching documents.</p>
        </div>

        <div className="c-product-related__content">
            <div className="c-card c-card--flex">

                <div className="c-card__content c-card__content--full c-card__content--border-bottom">
                        <div className="md:flex">
                            <div className="c-column-form__col">
                                <div className="select-block">
                                    <label>1. Brand</label>
                                    <select defaultValue="Default" onChange={selectedValue => detailsStore.setSelectedDocumentationBrandKey(selectedValue.currentTarget.value)}>
                                        <option value="Default" disabled={false}>Select brand</option>
                                        {detailsStore.allDocumentationBrands.map(brand => {
                                            return brand && <option value={brand.key}>{brand.value}</option>
                                        })}                                           
                                        </select>
                                    </div>
                                </div>

                            <div className="c-column-form__col">
                                <div className="select-block">
                                        <label>2. Turbine type</label>
                                    <select defaultValue="Default" onChange={selectedValue => detailsStore.setSelectedDocumentationModelKey(selectedValue.currentTarget.value)}>
                                        <option value="Default" disabled={false}>Select turbine type</option>
                                        {detailsStore.filteredDocumentationModels.map(model => {
                                            return model && <option value={model.key}>{model.value}</option>
                                        })}  
                                        </select>
                                    </div>
                                </div>

                            <div className="c-column-form__col">
                                <div className="select-block">
                                        <label>3. MK version</label>
                                    <select defaultValue="Default" onChange={selectedValue => detailsStore.setSelectedDocumentationModelVariantKey(selectedValue.currentTarget.value)}>
                                        <option value="Default" disabled={false}>Select MK version</option>
                                        {detailsStore.filteredDocumentationModelVariants.map(modelVariant => {
                                            return modelVariant && <option value={modelVariant.key}>{modelVariant.value}</option>
                                        })}  
                                        </select>
                                    </div>
                                </div>
                            </div>

                    <div className="text-sm mt-xs">{detailsStore.filteredTechnicalDocumentation.length + detailsStore.filteredCommercialDocumentation.length} documents match the criteria</div>
                </div>

                <div className="c-card__content c-card__content--half c-card__content--border-right">
                    <div className="font-bold mb-xs">Technical documentation</div>

                    <div className="o-flexlist my-xs">
                        {
                            generateDocumentationSection(detailsStore.filteredTechnicalDocumentation)
                        }
                    </div>
                </div>

                <div className="c-card__content c-card__content--half">
                    <div className="font-bold mb-xs">Commercial documentation</div>

                    <div className="o-flexlist my-xs">
                        {
                            generateDocumentationSection(detailsStore.filteredCommercialDocumentation)
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
});
