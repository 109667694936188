import * as React from 'react';
import { Router, Switch, Route, useParams } from "react-router-dom";
import { Catalog } from './pages/catalog';
import { Details } from './pages/details';
import { observer } from 'mobx-react';
import { history } from './contexts/';
import { SiteHeader } from './components/SiteHeader';
import { useStores } from './hooks/UseStores';
import { Error } from './components/Error';
import { PageTracker } from './components/PageTracker';
import { useEffect } from 'react';

declare global {
    interface Window {
        dataLayer: any;
    }
}

window.dataLayer = window.dataLayer || [];

export const App = observer(() => {
    const { detailsStore, modelStore, searchStore, catalogStore } = useStores();
    const error = detailsStore.error || modelStore.error || searchStore.error || catalogStore.error;

    return (
        <Router history={history} >
        <div id="app" className="main bg-white">
            
            <SiteHeader/>
            {error && <Error />}
            {!error &&
               
                    <Switch>
                    <Route path="/service/:id">
                            <SetDetailsId />
                            <Catalog hide={true} />
                            <Details />
                        </Route>
                        <Route path="*">
                            <Catalog hide={false} />
                        </Route>
                    </Switch>
                
            }
            <PageTracker />
            </div>
        </Router>
        );
    }
);

const SetDetailsId = () => {
    const { id } = useParams();
    const { detailsStore } = useStores();

        useEffect(() => {

            detailsStore.setEntityId(id);
        });


    return null;
};
