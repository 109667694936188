/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */

import * as request from "superagent";
import { IWorkareaFolderModel,IWorkareaTreeFolderModel,IEntityListModel,IQueryModel,IWorkareaCreationModel } from "./models"
import { requestWithToken } from '../adalConfig';

export class WorkareaHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public getWorkareaFolders( includeCreatedByMe?:boolean , includeShared?:boolean , forUsername?:string ):Promise<Array<IWorkareaFolderModel>>{
        const params = {
            "includeCreatedByMe":includeCreatedByMe,
            "includeShared":includeShared,
            "forUsername":forUsername
        };
        return new Promise<Array<IWorkareaFolderModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/workareafolders`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IWorkareaFolderModel>);
                        }
                    });
            });
        });
    }
    
    public getWorkareaFolderTree( includeCreatedByMe?:boolean , includeShared?:boolean , forUsername?:string ):Promise<Array<IWorkareaTreeFolderModel>>{
        const params = {
            "includeCreatedByMe":includeCreatedByMe,
            "includeShared":includeShared,
            "forUsername":forUsername
        };
        return new Promise<Array<IWorkareaTreeFolderModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/workareafoldertree`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IWorkareaTreeFolderModel>);
                        }
                    });
            });
        });
    }
    
    public getWorkareaQueryResult( workareaFolderId?:string ):Promise<IEntityListModel>{
        return new Promise<IEntityListModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/workareafolder/${workareaFolderId}/entitylist`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntityListModel);
                        }
                    });
            });
        });
    }
    
    public putUpdateWorkareaQuery( workareaFolderId?:string , queryModel?:IQueryModel ):Promise<IWorkareaFolderModel>{
        return new Promise<IWorkareaFolderModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/workareafolder/${workareaFolderId}/query`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IWorkareaFolderModel);
                        }
                    });
            });
        });
    }
    
    public getWorkareaFolderEntityIds( workareaFolderId?:string ):Promise<Array<number>>{
        return new Promise<Array<number>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/workareafolder/${workareaFolderId}/entityIds`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<number>);
                        }
                    });
            });
        });
    }
    
    public putWorkareaFolderEntityIds( workareaFolderId?:string , entityIds?:Array<number> ):Promise<Array<number>>{
        return new Promise<Array<number>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/workareafolder/${workareaFolderId}/entityIds`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<number>);
                        }
                    });
            });
        });
    }
    
    public putUpdateWorkarea( workareaFolderId?:string , workareaFolderModel?:IWorkareaFolderModel ):Promise<IWorkareaFolderModel>{
        return new Promise<IWorkareaFolderModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .put(`${this.baseUrl}/api/v1.0.0/workareafolder/${workareaFolderId}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IWorkareaFolderModel);
                        }
                    });
            });
        });
    }
    
    public deleteDeleteWorkarea( workareaFolderId?:string ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .delete(`${this.baseUrl}/api/v1.0.0/workareafolder/${workareaFolderId}`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public postCreateWorkarea( creationModel?:IWorkareaCreationModel ):Promise<IWorkareaFolderModel>{
        return new Promise<IWorkareaFolderModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .post(`${this.baseUrl}/api/v1.0.0/workareafolder:createnew`)
                    .send(creationModel)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IWorkareaFolderModel);
                        }
                    });
            });
        });
    }
}



