import { useEffect } from 'react'
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useStores } from '../hooks/UseStores'

export const PageTracker = observer(() => {
    const { detailsStore, routingStore } = useStores();


    // Track page view through Goggle Tag Manager dataLayer
    useEffect(() => autorun(() => {
        const { location } = routingStore.history;
        const { serviceInfoData, details } = detailsStore;

        if (location.pathname.startsWith('/service/')) {
            // For service details, only track when the serivce data have been loaded
            if(serviceInfoData.sapMaterialID) {
                // Map details to format that is expected by GTM
                const detailsObject = details && details.map(item => {
                    return { [item.name]: item.value };
                });

                // Track in GTM
                window.dataLayer.push({
                    event: 'virtualPageview',
                    location: window.location.href,
                    pageUri: location.pathname,
                    vestasSapMaterialId: serviceInfoData.sapMaterialID,
                    vestasProductName: serviceInfoData.name,
                    vestasAdditionalData: detailsObject
                });
            }
        } else {
            // Other pages can just be tracked as we don't need to enrich them
            window.dataLayer.push({
                event: 'virtualPageview',
                location: window.location.href,
                pageUri: location.pathname,
                vestasSapMaterialId: undefined,
                vestasProductName: undefined,
                vestasAdditionalData: undefined
            });
        }
    }));

    return null;
});
