/*****************************AutoGenerated Code : Do not edit *******************************/
// Type generated from Swagger definition

/* tslint:disable:max-classes-per-file */
/* tslint:disable:array-type */

import * as request from "superagent";
import { IChannelSummaryModel, IEntityListModel, IStructureEntityModel, IChannelPathContentModel } from "./models"
import { requestWithToken } from '../adalConfig';


export class ChannelHttpSvc {
    private baseUrl: string = "";;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }
    
    public getGetChannelsForEntityId( forEntityId?:number , includeChannels?:boolean , includePublications?:boolean ):Promise<Array<IChannelSummaryModel>>{
        const params = {
            "forEntityId":forEntityId,
            "includeChannels":includeChannels,
            "includePublications":includePublications
        };
        return new Promise<Array<IChannelSummaryModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IChannelSummaryModel>);
                        }
                    });
            });
        });
    }
    
    public getEntityTypes( channelId?:number ):Promise<Array<string>>{
        return new Promise<Array<string>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/${channelId}/entitytypes`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<string>);
                        }
                    });
            });
        });
    }
    
    public getGetByLinkEntityType( channelId?:number , entityTypeId?:string ):Promise<IEntityListModel>{
        const params = {
            "entityTypeId":entityTypeId
        };
        return new Promise<IEntityListModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/${channelId}/entitylist`)
                    .set('Authorization', `bearer ${token}`)
                    .query(params)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntityListModel);
                        }
                    });
            });
        });
    }
    
    public getGetByEntityType( channelId?:number , entityId?:number , linkDirection?:string , linkTypeId?:string ):Promise<IEntityListModel>{
        const params = {
            "linkDirection":linkDirection,
            "linkTypeId":linkTypeId
        };
        return new Promise<IEntityListModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/${channelId}/entities/{entityId}/links`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IEntityListModel);
                        }
                    });
            });
        });
    }
    
    public getGetChannelNodesAll( channelId?:number , entityId?:number ):Promise<Array<IStructureEntityModel>>{
        return new Promise<Array<IStructureEntityModel>>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/${channelId}/entities/{entityId}/structureentities`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as Array<IStructureEntityModel>);
                        }
                    });
            });
        });
    }
    
    public getGetChannelNodes( channelId?:number ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/${channelId}/nodes`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public getGetChannelNodeTree( channelId?:number ):Promise<any>{
        return new Promise<any>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/${channelId}/nodetree`)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as any);
                        }
                    });
            });
        });
    }
    
    public getChannelContent( path?:string , entityTypeIds?:string ):Promise<IChannelPathContentModel>{
        const params = {
            "entityTypeIds":entityTypeIds
        };
        return new Promise<IChannelPathContentModel>((resolve, reject) => {
            requestWithToken((tokenError, token) => {
                request
                    .get(`${this.baseUrl}/api/v1.0.0/channels/content/${path}`)
                    .query(params)
                    .set('Authorization', `bearer ${token}`)
                    .end((error, res) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(res.body as IChannelPathContentModel);
                        }
                    });
            });
        });
    }
}



