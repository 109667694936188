import * as React from 'react';
import { IQueryModel, IEntityTypeModel, ICVLModel, ICVLValueModel } from '../../InRiverApiClient/models';

export interface IQueryContextValue {
    query: IQueryModel,
    updateQuery: (IQueryModel) => void,
    combinedQuery: IQueryModel,
    entityTypes: IEntityTypeModel[],
    cvls: ICVLModel[],
    cvlValues: Map<string, ICVLValueModel[]>,
    language: string
}

const QueryContext = React.createContext({
    query: {},
    combinedQuery: {}
} as IQueryContextValue);

export default QueryContext;